export const ADMIN_USER_PAGE = '/adm/*'
// export const ADMIN_POSTAV_PAGE = "/post/"
export const AUTH_ROUTE = '/'
export const BASKET_ROUTE = '/personal/basket/'
export const USER_ROUTE = [
    '/personal/:searchParam',
    '/personal/'
]


// export const CATALOG_ROUTE = [
//     // ###BEST###
//     // "/catalog/bestseller/:childCategory/filter/:filterParam/",
//     // "/catalog/bestseller/filter/:filterParam/",
//     // "/catalog/bestseller/",
//     // ###END BEST###
//     "/catalog/:parrentCategory/:childCategory/filter/:filterParam/",
//     "/catalog/:parrentCategory/",
//     "/catalog/:parrentCategory/:childCategory/",
//     "/catalog/:parrentCategory/filter/:filterParam/",
//     "/catalog/filter/:filterParam",
//     "/catalog/",
// ]
//


export const DataArray = [2023,2024,2025,2026];
