import React, {useContext} from 'react';
import './exitButton.css';
import {Context} from "../../../index";
const ExitButton = () => {
    const {user} = useContext(Context);
    const exitUser = () => {
        user.setUser("");
        user.setIsAuth(false);
        user.setStepModals('registration')
        localStorage.token = "";
    }
    return (
        <div onClick={e => exitUser()} className={"exit_user_block"}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="800px" height="800px" viewBox="0 0 16 16">
                <path d="M12.207 9H5V7h7.136L11.05 5.914 12.464 4.5 16 8.036l-3.536 3.535-1.414-1.414L12.207 9zM10 4H8V2H2v12h6v-2h2v4H0V0h10v4z" fill-rule="evenodd"/>
            </svg>
        </div>
    );
};

export default ExitButton;