import React, {useContext, useEffect, useState} from 'react';
import {observer, Observer} from "mobx-react-lite";
import {Context} from "../../index";
import "./DetailSetPage.css";
import {useLocation} from "react-router";
import {Link, useParams} from "react-router-dom";
import {getFullSetInfo, setArticulTable} from "../../http/setApi";
import SuccessModal from "../../components/UI/SuccessModal/SuccessModal";
import {logDOM} from "@testing-library/react";



const DetailSetPage = observer(() => {

    const {settings,user} = useContext(Context);
    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)

    const [mlArray, setMlArray] = useState(null);
    const [setData, setDataSet] = useState(null);
    const [setTable,setSetTable] = useState(null);
    const [openSuccessModal,setOpenSuccessModal] = useState(false);

    let page = +queryParams.get('page');


    let id = params.id;


    useEffect(() => {

        getFullSetInfo(id).then(response => {
            if(response.setData){
                setDataSet(response.setData)

                let info = response.setTable;
                info.sort(function(a, b) {
                    return parseFloat(a.value) - parseFloat(b.value);
                });
                setSetTable(info)

                // console.log(info)
                console.log(response)

            }
        })

    },[])


    const changeArticulHandler = (index,value) => {
        let newSetTable = [...setTable];
        newSetTable[index].articul = value
        setSetTable(newSetTable)
    }

    const saveSetTableItemArticul = (id,item) => {
        setArticulTable(id,item).then(response => {
            if(response.message) {
                setOpenSuccessModal(true);
                user.setShadowOverlay(true)
            }
        })
    }


    useEffect(() => {
        if(user.shadowOverlay === false) {
            setOpenSuccessModal(false);

        }
    },[user.shadowOverlay])



    if(user.getUserRights && user.getUserRights.view_sets) {
        return (
            <div className={"set_page_wrapper"}>
                <SuccessModal isOpen={openSuccessModal} text={"Артикул успешно обновлен!"} />
                <div className={"set_content_page_wrapper"}>
                    <div className={"name_container"}>
                        <span className={"name_page_set"}>{setData ?  setData.name : ""}</span>
                        {
                            user.getUserRights && user.getUserRights.edit_sets &&
                            <Link className={"go_to_redact_set"} to={`/adm/sets/edit/${id}`}>Изменить</Link>
                        }

                    </div>
                    <div className={"variable_set_box"}>
                        {setTable &&
                            <div className={"parrent_set_wrap"}>
                                <div className={"top_set_ll"}>
                                    <div className={"vid_nab utl"}>Вид набора</div>
                                    <div className={"art_set utl"}>Артикул</div>
                                    <div className={"sve_set utl"}>Действие</div>
                                </div>
                                {
                                    setTable ? setTable.map((item, index) =>
                                        item.isActive &&
                                        <div key={index} className={"bot_set_ll"}>
                                            <div className={"vid_nab cpl"}>{setData.name+" по "+item.value+" мл"}</div>
                                            <input onChange={ e => changeArticulHandler(index, e.target.value)} value={item.articul ? item.articul : ""} className={"art_set cpl"}></input>
                                            <div className={"sve_set cpl"}> <span className={"span_to_save"} onClick={e => saveSetTableItemArticul(item.id,item)}>Сохранить</span> </div>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        }

                        {setData &&
                            <div className={"detail_set_aromat_list_wrapper"}>
                                <div className={"detail_set_list_top"}>
                                    <div className={"aroma_info_set_container utl"}>Состав набора</div>
                                    <div className={"aroma_set_link utl"}>Ссылка на товар</div>
                                </div>
                                {
                                    setData ? setData.items_set_table.map((item, index) =>
                                        <div key={index} className={"detail_set_list_bottom"}>
                                            <div className={"aroma_info_set_container cpl"}>{item.name}</div>
                                            <div className={"aroma_set_link cpl"}>
                                                <Link target="_blank" className={"link_btn_detail_info"} to={item.link ?  item.link : "/"}>АлюрПарфюм</Link>
                                                <Link className={"link_btn_detail_info"} to={`/adm/aroma/${item.id}`}> В каталоге </Link>
                                            </div>
                                        </div>
                                    ) : ''
                                }
                            </div>
                        }

                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className={"close_page_for_you"}>
                <span>Доступ закрыт</span>
            </div>
        )
    }



});

export default DetailSetPage;


