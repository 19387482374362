import React, {useContext} from 'react';
import {Link} from "react-router-dom";
import {Context} from "../../../../index";
import {observer} from "mobx-react-lite";

const Logotype = observer((props) => {

    const {settings} = useContext(Context)

    const logoHeaderPath =  process.env.REACT_APP_API_URL+"/files/images/ui/logo_header.png";

    return (

        <div>
            {
                settings.projectWidth && settings.projectWidth > 768 ?
                    <Link to={"/"} className={props.stateSearcht === false ? "logotype_wrap" : "logotype_wrap nonactive" } href="/">
                        <img src={"/files/images/ui/logo_header.png"} alt="YouWanna - интернет-магазин одежды и аксессуаров"/>
                    </Link>
                    :
                    <Link to={"/"} className={props.stateSearcht === false ? "logotype_wrap" : "logotype_wrap nonactive" } href="/">

                        {
                            props.stateSearcht === false  ?
                                <img src={"/files/images/ui/logo_header.png"} alt="YouWanna - интернет-магазин одежды и аксессуаров"/>
                                :
                                ''
                        }
                    </Link>
            }

        </div>


    );
});

export default Logotype;