import {
    // ADMIN_POSTAV_PAGE,
    ADMIN_USER_PAGE, AUTH_ROUTE,
}
    from "./utils/consts"
import UserPage from "./pages/administrator/UserPage"
// import PostavshikiPage from "./pages/postavshiki/PostavshikiPage";
import AuthPage from "./pages/authPage/AuthPage";
import RouteAdminPage from "./pages/administrator/RouteAdminPage";






export const authRoutes = [
    {
        path: ADMIN_USER_PAGE,
        Component: RouteAdminPage
    },
]


export const publicRoutes = [
    {
        path: AUTH_ROUTE,
        Component: AuthPage,
    },
    // {
    //     path: CATALOG_LENDING_ROUTE,
    //     Component: CatalogLanding,
    // },
    // {
    //     path: CONTENT_PAYMENT_PAGE,
    //     Component: Payment,
    // },
]
