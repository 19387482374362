import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import './BrandPage.css';
import {Route, Routes, useParams} from "react-router-dom";

import SuccessModal from "../../components/UI/SuccessModal/SuccessModal";

import PostavshikAddModal from "../../components/UI/PostavshikAddModal/PostavshikAddModal";
import {changeActive, createPost, fetchAllPost, fetchDeactivePosts, updatePost} from "../../http/PostavAPI";
import AnswerModalDeactive from "../../components/UI/AnswerModalDelete/AnswerModalDeactive";
import {fetchAllUsers} from "../../http/userAPI";
import PostavshikUpdateModal from "../../components/UI/PostavshikUpdateModal/PostavshikUpdateModal";
import BrandUpdateModal from "../../components/UI/BrandUpdateModal/BrandUpdateModal";
import BrandAddModal from "../../components/UI/BrandAddModal/BrandAddModal";
import {
    changeActiveBrand,
    createBrand,
    deleteBrand,
    fetchAllBrand,
    fetchDeactiveBrand,
    updateBrand
} from "../../http/BrandApi";
import {useLocation} from "react-router";
import Pagination from "../../components/UI/Paginstion";
import userRights from "../userRights/UserRights";








const BrandPage = observer(() => {



    const {settings,user} = useContext(Context);
    const [openAddPostModal, setOpenAddPostModal] =  useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [successText, setSuccessText] = useState('');
    const [errorAddPost, setErrorAddPost] = useState('');

    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState();
    const [selectedUser, setSelectedUser] = useState(null);
    const [openUserModal, setOpenUserModal] = useState(false)
    const [openSuccessCreateModal,setOpenSuccessCreateModal] = useState(false);
    const [allCount,setAllCount] = useState(0)
    const [postavArray, setPostavArray] = useState(null);
    const [deactiveArray, setDeactiveArray] = useState(null);
    const [refresh,setRefresh] = useState(false);
    /////

    const [errorUpdateModal, setErrorUpdateModal] = useState('');
    const [openUpdate, setOpenUpdate] = useState(false);
    const [idUpdate,setIdUpdate] = useState('');

    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')


    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }

    const limit = 50;
    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0)

    useEffect(() => {
        fetchAllBrand(limit,(page-1)*limit,true,searchRoleParam,searchQuery).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }
            setAllCount(response.count)
            setCount(response.count)
            setPostavArray(response.rows);
            console.log(response.rows)
        })

        fetchDeactiveBrand().then(response => {
            console.log('RESPONSE DEACTIVE');
            console.log(response.rows)
            setDeactiveArray(response.rows);
        })

    }, [page,searchRoleParam,searchQuery, refresh])

    ///DEactiv
    const [opAnswModal,setOpAnswModal] = useState(false);
    const [textAnswModal, setTextAnswModal] = useState('');
    const [btnText, setBtnText] = useState('');
    const [idAnswModal, setIdAnswModal] = useState('');


    const openAnswModalDeactive = (item) => {
        let text = item.isActive ? "Деактивировать" : "Активировать";
        setBtnText(text)
        setTextAnswModal(`Вы точно хотите ${text} данный бренд?`);
        setIdAnswModal(item.id)
        setOpAnswModal(true)
        user.setShadowOverlay(true);
    }
    const deleteAndDeactiveHandler = async (fun,id) => {
        if(fun==="del") {

        }
        if(fun==='deactiv') {
            let response = await changeActiveBrand(id);
            console.log(response)
            if(response.message) {
                let text = response.text;
                setSuccessText(`Бренд ${text} успешно`)
                setOpAnswModal(false)
                setOpenSuccessModal(true)
                setRefresh(!refresh)
                if(!user.shadowOverlay){
                    user.setShadowOverlay(true)
                }
            }

            if(response.error) {
                return false;
            }
        }
    }

    const closeAnswModal = () => {
        setOpAnswModal(false);
        user.setShadowOverlay(false);
    }



    ///EXIT DELL
    const modalOpenPostHandler = () => {
        setOpenAddPostModal(true);
        user.setShadowOverlay(true);
    }

    const createPostHandler = async (name) => {
        let response = await createBrand(name);
        console.log(response)
        if(response.message) {
            setSuccessText('Бренд добавлен успешно')
            setOpenSuccessModal(true)
            setErrorAddPost("")
            setRefresh(!refresh)
            setOpenAddPostModal(false)
        }

        if(response.error) {
            setErrorAddPost(response.error)
        }
    }

    const openUpdHandler = (id) => {
        setIdUpdate(id);
        setOpenUpdate(true)
        user.setShadowOverlay(true);
    }
    const updatePostHandler = async (id,name) => {

        const response = await updateBrand(id,name);

        if(response.message) {
            setSuccessText('Бренд обновлен успешно')
            setOpenSuccessModal(true)
            setErrorUpdateModal("")
            setOpenUpdate(false);
            setRefresh(!refresh)

        }

        if(response.error) {
            setErrorUpdateModal(response.error)
        }
    }


    useEffect(() => {
        if(user.shadowOverlay === false) {
            setOpenAddPostModal(false);
            setOpenSuccessModal(false);
            setOpAnswModal(false)
            setOpenUpdate(false)

        }
    },[user.shadowOverlay])


    const [deactiveAreaOpen, setDeactiveAreaOpen] = useState(false);

    const deactiveAreaHandler = () => {
        setDeactiveAreaOpen(!deactiveAreaOpen);
    }

    if(user.getUserRights && user.getUserRights.guide_brand_view) {
        return (
            <div  className={"main_page_container_post"}>
                <BrandAddModal error={errorAddPost}  createPostHandler={createPostHandler} setOpenAddPostModal={setOpenAddPostModal} isOpen={openAddPostModal} />
                <BrandUpdateModal error={errorUpdateModal} idUpd={idUpdate} updatePostHandler={updatePostHandler} setOpenUpdate={setOpenUpdate} isOpen={openUpdate} />

                <SuccessModal isOpen={openSuccessModal} text={successText} />
                <AnswerModalDeactive isOpen={opAnswModal} text={textAnswModal} buttonText={btnText} function={deleteAndDeactiveHandler} id={idAnswModal} close={closeAnswModal}  />

                <div className={"post_container"}>
                    <div className={"active_posts"}>
                        <div className={'heading_urp_block'}>
                            <span className={"name_page"}>СПРАВОЧНИК БРЕНДОВ</span>
                            <div className={'seach_container'}>
                                <input
                                    className={"search_input"}
                                    value={searchQuery ? searchQuery : ""}
                                    onChange={ e => setSearchQuery(e.target.value)}
                                    placeholder={"Поиск..."} type="text"/>
                                <div className={"abs_lupa"}>
                                    <img src="/lupa.png" alt=""/>
                                </div>
                            </div>
                            {
                                user.getUserRights && user.getUserRights.guide_brand_edit &&
                                <span onClick={e => modalOpenPostHandler()} className={"add_new_btn brand"}>
                                    Добавить бренд
                                </span>
                            }


                        </div>
                        <div className={"heading_line_post"}>
                            <span className={"heading_line_post_name"}>Бренд</span>
                            <span className={"heading_line_post_work"}>Действие</span>
                        </div>
                        {
                            postavArray ? postavArray.map((item, index) =>
                                <div className={item.isActive ? "heading_line_post" : "heading_line_post deactive"} key={index}>
                                    <div className={'name_line_post'}>{item.name}</div>
                                    {
                                        user.getUserRights && user.getUserRights.guide_brand_edit &&
                                        <div className={"post_container_func"}>
                                            <span onClick={e => openUpdHandler(item.id)} className={"bnt_post"}>Редактировать</span>
                                            <span onClick={e => openAnswModalDeactive(item)} className={item.isActive ? "bnt_post" : "bnt_post deact"}>{item.isActive ? "Деактивировать" : "Активировать"}</span>
                                        </div>
                                    }

                                </div>
                            ) : ''
                        }
                        <Pagination page={page} limit={limit} count={count}/>

                    </div>



                </div>
            </div>
        );
    } else {
        return (
            <div className={"close_page_for_you"}>
                <span>Доступ закрыт</span>
            </div>
        )
    }

});

export default BrandPage;

