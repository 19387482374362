import React, {useContext, useEffect, useState} from 'react';
import './Header.css';
import {useLocation} from "react-router";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import ShadowOverlay from "../UI/ShadowOverlay/ShadowOwerlay";
import {Link} from "react-router-dom";
import ExitButton from "../UI/ExitButton/ExitButton";
import Preloader from "../UI/preloader/Preloader";


const Header = observer((props) => {


    const {settings, user} = useContext(Context)

//Получаем роуты пользовательские и сравниваем с текущим. Забирая от туда цвет хедера.
    let location = useLocation();

    const closeAuthModalFunction = () => {
        user.setShadowOverlay(false);
        user.setOpenAuthModal(0);

    }

    useEffect(() => {
        const elHeader = document.getElementById('header').clientHeight
        const elFooter = document.getElementById('footer').clientHeight
        const projectWidth = window.innerWidth
        const projectHeight = window.innerHeight

        console.log('=================');
        console.log(projectHeight)
        console.log(elHeader);
        console.log(elFooter)
        console.log('====================')
        if(projectWidth && projectHeight) {
            settings.setProjecHeight(projectHeight);
            settings.setProjectWidth(projectWidth);
        }

        // const screenHeight = window.screen.height
        const bodySize = projectHeight * 1 - (elHeader * 1 + elFooter * 1);
        // console.log(bodySize)
        const headerAndFooter = elHeader * 1 + elFooter * 1;
        // console.log(projectHeight -  headerAndFooter)
        settings.setBodySize(bodySize);
    },[])

    return (
        <div>
            <ShadowOverlay className={user.shadowOverlay === true ? "active" : "hide"}
                           closeClick={() => closeAuthModalFunction()}/>
            <Preloader/>
            <header
                id={"header"}
                className={"header_main"}
            >
                {
                    user.isAuth ?
                        <div className={"link-header-container"}>
                            <div className={"wrapper_item_header"}>
                                <Link className={"page_heading_items"} to={'/adm/brand'}> Бренды </Link>
                            </div>
                            <div className={"wrapper_item_header"}>
                                <Link className={"page_heading_items"} to={'/adm/aroma'}> Ароматы </Link>
                            </div>
                            <div className={"wrapper_item_header"}>
                                <Link className={"page_heading_items"} to={'/adm/sets'}> Наборы </Link>
                            </div>
                            <div className={"wrapper_item_header"}>
                                <div className={settings.noLink ?  "indicator active" : "indicator"}> {settings.noLink} </div>
                                <Link className={"page_heading_items"} to={'/adm/nolink?page=1&selected=aromat'}> Без ссылок </Link>
                            </div>
                            <div className={"wrapper_item_header"}>
                                <div className={settings.noArt ?  "indicator active" : "indicator"}> {settings.noArt} </div>
                                <Link className={"page_heading_items"} to={'/adm/noart?page=1&selected=aromat'}> Без артикулов </Link>
                            </div>
                            <div className={"wrapper_item_header"}>
                                <div className={settings.wrongItems ?  "indicator active" : "indicator"}> {settings.wrongItems} </div>
                                <Link className={"page_heading_items"} to={'/adm/wrongitems'}> Ошибки списания </Link>
                            </div>
                            <div className={"wrapper_item_header"}>
                                <Link className={"page_heading_items"} to={'/adm/post'}> Поставщики </Link>
                            </div>
                            <div className={"wrapper_item_header"}>
                                <Link className={"page_heading_items"} to={'/adm/user'}> Пользователи </Link>
                            </div>
                            <div className={"wrapper_item_header"}>
                                <Link className={"page_heading_items"} to={'/adm/dashboard/ost/'}> Дашборд </Link>

                            </div>

                            <div className={"wrapper_item_header"}>
                                <Link className={"page_heading_items"} to={'/adm/allparams'}> Справочник </Link>
                            </div>



                            <ExitButton/>
                        </div>
                    : ""
                }

            </header>
        </div>

    );
});

export default Header;
