import React, {useContext, useEffect, useState} from 'react';
import {Link, useParams} from "react-router-dom";
import {converter_RUB_S, converter_S_RUB, getHumanDate, priceToUserString} from "../../http/SupportFunctions";
import {changeActiveEntrance, fetchEntranceById, updateEntrance} from "../../http/AromaApi";
import {useLocation} from "react-router";
import Pagination from "../UI/Paginstion";
import {Context} from "../../index";
import SuccessModal from "../UI/SuccessModal/SuccessModal";
import AnswerModalDeactive from "../UI/AnswerModalDelete/AnswerModalDeactive";
import {observer} from "mobx-react-lite";
import {changeActiveBrand} from "../../http/BrandApi";
import {fetchAllPost} from "../../http/PostavAPI";

const PostavshArea = observer((props) => {

    const {settings,user} = useContext(Context);

    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState();
    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page-en')
    const [entrance,setEntrance] = useState(null);
    const [refresh,setRefresh] = useState(false);


    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page-en")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }

    const limit = 50;
    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0)

    let id = params.id;

    useEffect(() => {
        fetchEntranceById(limit,(page-1)*limit,true,searchRoleParam,searchQuery, id).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }
            console.log(response)
            setCount(response.count)
            setEntrance(response.rows);

        })
    },[page,limit,searchQuery,refresh])

    ///DEactiv
    const [opAnswModal,setOpAnswModal] = useState(false);
    const [textAnswModal, setTextAnswModal] = useState('');
    const [btnText, setBtnText] = useState('');
    const [idAnswModal, setIdAnswModal] = useState('');
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [successText, setSuccessText] = useState('');


    const openAnswModalDeactive = (item) => {
        let text = item.isActive ? "Деактивировать" : "Активировать";
        setBtnText(text)
        setTextAnswModal(`Вы точно хотите ${text} данную поставку?`);
        setIdAnswModal(item.id)
        setOpAnswModal(true)
        user.setShadowOverlay(true);
    }
    const closeAnswModal = () => {
        setOpAnswModal(false);
        user.setShadowOverlay(false);
    }




    const changeActiveEntranceHandler = async () => {
        let response = await changeActiveEntrance(idAnswModal);
        // console.log(response)
        if(response.message) {
            setOpAnswModal(false);
            user.setShadowOverlay(false);
            setRefresh(!refresh);
        }
    }


    const [postSelect, setPostSelect] = useState(null);
    const [sostavshikArray, setPostavshikArray] = useState(null);
    const [postavSearchQuery, setPostavSearchQuery] = useState('')
    const [openPostavSelect, setOpenPostavSelect] = useState(false);
    const [messageBackend, setMessageBackend] = useState('');
    const [typeMessage, setTypeMessage] = useState(false);

    const selectPostHandler = (item) => {
        setPostSelect(item)
        setOpenPostavSelect(false);
    }

    const [updatePost, setUpdatePost] = useState(null);
    const [openUpdatepost,setOpenUpdatePost] = useState(false)
    const openRedactHandler = (item) => {
        setUpdatePost(item);
        user.setShadowOverlay(true);
        setOpenUpdatePost(true);
        setPostSelect(item.postavshik)
        console.log(item)
    }

    useEffect(() => {
        fetchAllPost(999,1,true,searchRoleParam,postavSearchQuery).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }
            setPostavshikArray(response.rows);
        })
    },[page,postavSearchQuery])


    const [ruTriger,setRuTriger] = useState(false);
    const [sTruger,setSTriger] = useState(false)
    const [procentPriceChange,setProcentPriceChange] = useState(null)



    useEffect( () => {

        if(updatePost) {
            if(updatePost.price_S) {
                console.log("trr")
                let priceR = converter_S_RUB(updatePost.price_S, settings.getRubCurse)
                let priceRml = converter_S_RUB(updatePost.ml_price_S, settings.getRubCurse)
                let mlPrice_s = updatePost.price_S/ updatePost.volume;
                let mlPrice_r = converter_S_RUB(mlPrice_s,settings.getRubCurse);
                console.log("PRICE ML S " + mlPrice_s)
                // за флакон
                setUpdatePost({...updatePost, price_R:priceR , ml_price_S:mlPrice_s,  ml_price_R:mlPrice_r })

            }
        }


    },[sTruger])

    useEffect( () => {
        console.log("RU TRIGGER")
        if(updatePost) {
            if(updatePost.price_R) {
                let priceS = converter_RUB_S(updatePost.price_R, settings.getRubCurse)
                let priceSml = (priceS / updatePost.volume).toFixed(2);
                let mlPrice_RU = updatePost.price_R / updatePost.volume;
                // let mlPrice_r = converter_RUB_S(mlPrice_st,CURSE);
                //
                // за флакон

                setUpdatePost({...updatePost, price_S:priceS , ml_price_S:priceSml,  ml_price_R:mlPrice_RU })
            }
        }

    },[ruTriger])


    useEffect(() => {
        if(user.shadowOverlay === false) {
            // setOpenAddPostModal(false);
            setOpenSuccessModal(false);
            setOpAnswModal(false)
            setOpenUpdatePost(false);

        }
    },[user.shadowOverlay])


    const saveHandlerUpdatePost = (item,newpost) => {
        updateEntrance(item,newpost).then(response => {
            console.log(response)
            if(response.message){
                setOpenUpdatePost(false);
                setOpenPostavSelect(false);
                setSuccessText("Успешно обновлено")
                setOpenSuccessModal(true)
            }
        })
    }


    if(user.getUserRights && user.getUserRights.view_history_post ) {
        return (
            <div className={props.active ? "postavsh_area active" : "postavsh_area"}>
                <SuccessModal isOpen={openSuccessModal} text={successText} />
                <AnswerModalDeactive isOpen={opAnswModal} text={textAnswModal} buttonText={btnText} function={changeActiveEntranceHandler} id={idAnswModal} close={closeAnswModal}  />
                {
                    updatePost &&
                    <div className={openUpdatepost ? "modal_redact_entrance active" : "modal_redact_entrance"}>
                        <div className={"wrapper_inp_red"}>
                            <span>Поставщик</span>
                            <div   className={ openPostavSelect ? "entrance_selector_container active" : "entrance_selector_container" }>
                                <span onClick={e => setOpenPostavSelect(!openPostavSelect)}> { postSelect ? postSelect.name : "Выберите поставщика"  }</span>
                                <div className={"menu_hidden_area"}>

                                    <div className={"aroma_wrapp_for_search"}>
                                        <div className={'seach_container'}>
                                            <input
                                                className={"search_input"}
                                                value={postavSearchQuery ? postavSearchQuery : ""}
                                                onChange={ e => setPostavSearchQuery(e.target.value)}
                                                placeholder={"Поиск..."} type="text"/>
                                            <div className={"abs_lupa"}>
                                                <img src="/lupa.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"scroll_container_aroma"}>
                                        {
                                            sostavshikArray ? sostavshikArray.map((item, index) =>
                                                <div onClick={e => selectPostHandler(item)} className={"wrapper_for_select_brand"} key={index}>
                                                    <span  className={"one_brand_item"}>{item.name}</span>
                                                </div>
                                            ) : ''
                                        }
                                    </div>
                                    <Link className={"redirect_button"} to="/adm/post" target="_blank" rel="noopener noreferrer">Добавить поставщика</Link>
                                </div>
                            </div>                    </div>


                        <div className={"wrapper_inp_red"}>
                            <span>Обьем</span>
                            <input className={"redactInput_tt"} onChange={e => setUpdatePost({...updatePost, volume:e.target.value})} value={updatePost ? updatePost.volume : ""} type="text"/>
                        </div>
                        <div className={"wrapper_inp_red"}>
                            <span>Кол-во флаконов</span>
                            <input className={"redactInput_tt"} onChange={e => setUpdatePost({...updatePost, cout:e.target.value})} value={updatePost ? updatePost.cout : ""} type="text"/>
                        </div>
                        <div className={"wrapper_inp_red"}>
                            <span>Цена $</span>
                            <input className={"redactInput_tt"} onChange={e => [setUpdatePost({...updatePost, price_S:e.target.value}), setSTriger(!sTruger)]} value={updatePost ? updatePost.price_S : ""} type="text"/>
                        </div>
                        <div className={"wrapper_inp_red"}>
                            <span>Цена ₽</span>
                            <input className={"redactInput_tt"} onChange={e => [setUpdatePost({...updatePost, price_R:e.target.value}),setRuTriger(!ruTriger)]} value={updatePost ? updatePost.price_R : ""} type="text"/>
                        </div>
                        <div className={"wrapper_inp_red"}>
                            <span>Цена за мл $</span>
                            <input className={"redactInput_tt"} onChange={e => setUpdatePost({...updatePost, ml_price_S:e.target.value})} value={updatePost ? updatePost.ml_price_S : ""} type="text"/>
                        </div>
                        <div className={"wrapper_inp_red"}>
                            <span>Цена за мл ₽</span>
                            <input className={"redactInput_tt"} onChange={e => setUpdatePost({...updatePost, ml_price_R:e.target.value})} value={updatePost ? updatePost.ml_price_R : ""} type="text"/>
                        </div>
                        <div className={"wrapper_inp_red"}>
                            <span>Примечание</span>
                            <input className={"redactInput_tt"} onChange={e => setUpdatePost({...updatePost, comment:e.target.value})} value={updatePost ? updatePost.comment : ""} type="text"/>
                        </div>

                        <div onClick={e => saveHandlerUpdatePost(updatePost,postSelect)} className={"save_update_btn"}>
                            сохранить
                        </div>




                    </div>
                }



                <div className="table">
                    <div className={"fix_container_v4"}>
                        <div className="table_top_line_names">
                            <div className="date_tt table_names">Дата</div>
                            <div className="volume_tt table_names">Объем, мл</div>
                            <div className="count_items_tt table_names">Кол-во флаконов</div>
                            <div className="price_s_tt table_names">Цена $ </div>
                            <div className="price_r_tt table_names">Цена ₽</div>
                            <div className="price_inf_tt table_names">Изменение цены,%</div>
                            <div className="price_ml_s_tt table_names">Цена за мл $ </div>
                            <div className="price_ml_r_tt table_names">Цена за мл ₽</div>
                            <div className="ost_price_ml_tt table_names">Цена остатка, ₽</div>
                            <div className="post_name_tt table_names">Поставщик</div>
                            <div className="user_name_tt table_names">Имя сотрудника</div>
                            <div className="active_tt table_names">Деактивация</div>
                            <div className="comment_tt table_names">Примечание</div>
                            <div className="redact_area table_names">Редактировать</div>
                        </div>

                        {
                            entrance ? entrance.map((item, index) =>
                                <div className={"heading_line_aroma"} key={index}>
                                    <div className="date_tt generated_line">{getHumanDate(item.createdAt)}</div>
                                    <div className="volume_tt generated_line">{item.volume ? item.volume : ""}</div>
                                    <div className="count_items_tt generated_line">{item.cout ? item.cout : ""}</div>
                                    <div className="price_s_tt generated_line">{item.price_S ? item.price_S : ""}</div>
                                    <div className="price_r_tt generated_line">{item.price_R ? priceToUserString(item.price_R) : ""}</div>
                                    <div className={ item.procent_change_price < 0 ? "price_inf_tt generated_line blue" : "price_inf_tt generated_line red"}>{item.procent_change_price ? item.procent_change_price+"%" : ""}</div>
                                    <div className="price_ml_s_tt generated_line">{item.ml_price_S ? item.ml_price_S : ""}</div>
                                    <div className="price_ml_r_tt generated_line">{item.ml_price_R ? priceToUserString(item.ml_price_R) : ""}</div>
                                    <div className="ost_price_ml_tt generated_line">{item.ost_price ? priceToUserString(item.ost_price) : ""}</div>
                                    <div className="post_name_tt generated_line">{item.postavshik.name ? item.postavshik.name : ""}</div>
                                    <div className="user_name_tt generated_line">{item.nameUser ? item.nameUser : ""}</div>
                                    <div className="active_tt generated_line">
                                        <div onClick={e => openAnswModalDeactive(item)} className={ item.isActive ? "circle_container" : "circle_container deactive"}>

                                        </div>
                                    </div>
                                    <div className="comment_tt generated_line">
                                        {
                                            item.comment ?
                                                <div className={"comment_area_nb"}>
                                                    <img src="/comment.png" alt=""/>
                                                    <div className={"comment_aft"}>
                                                        <div id="triangle"></div>
                                                        {item.comment}
                                                    </div>
                                                </div>
                                                : "-"
                                        }
                                    </div>
                                    <div className="redact_area table_names">
                                        <div className="pancel_container"  onClick={e => openRedactHandler(item)}>
                                            <img src="/pancel.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            ) : ''
                        }
                    </div>



                </div>
                {
                    props.activePage == "history-post" &&
                    <Pagination page={page} limit={limit} count={count} pageName={"page-en"}/>
                }
            </div>
        );
    } else {
                return (
                    <div className={props.active ? "postavsh_area active" : "postavsh_area"}>
                        <div className={"close_page_for_you"}>
                            <span>Доступ закрыт</span>
                        </div>
                    </div>
                )
        }


});

export default PostavshArea;

