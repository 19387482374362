import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables} from 'chart.js';
Chart.register(...registerables);
const Chartt = (props) => {

    console.log(props)
    const barChartData = {
        labels: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь","Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
        datasets: [
            {
                data: props.dataItems ? props.dataItems : [0, 0, 0, 0, 0, 0,0,0,0,0,0,0],
                label: props.title ? props.title : "default",
                borderColor: "#3333ff",
                backgroundColor: "rgba(195, 213, 236, 1)",
                fill: true
            },
        ]
    };

    const barChart = (
        <Bar
            type="bar"
            width={130}
            height={50}
            options={{
                title: {
                    display: true,
                    text: "COVID-19 Cases of Last 3 Months",
                    fontSize: 15
                },
                legend: {
                    display: true, //Is the legend shown?
                    position: "top" //Position of the legend.
                }
            }}
            data={barChartData}
        />
    );
    return barChart;
};

export default Chartt;

