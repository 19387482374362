function rem(numb) {
    numb = numb.toString();
    var index = numb.indexOf('.');
    if(!index) return 0;
    else return numb.slice(index+1).length;
}


export const priceToUserString = (price) => {
    let stringPrice = String(price);
    let lengthPrice = rem(stringPrice);
    switch (lengthPrice) {
        case 4:
            return stringPrice.substr(0,1)+" "+stringPrice.substr(1,lengthPrice)
            break;
        case 5:
            return stringPrice.substr(0,2)+" "+stringPrice.substr(2,lengthPrice)
            break;
        case 6:
            return stringPrice.substr(0,2)+stringPrice[2]+" "+stringPrice.substr(3,lengthPrice)
            break;
        case 7:
            return stringPrice.substr(0,1)+" "+stringPrice.substr(1,3)+" "+stringPrice.substr(4,lengthPrice)
            break;
        default:
            return (stringPrice*1).toFixed(2);

    }
}

export const CURSE = 94.8;
export const mlData = [
    {value: 1},
    {value: 2},
    {value: 3},
    {value: 5},
    {value: 10},
    {value: 15},

]
export const converter_S_RUB = (price, curse) => {
    return (price*curse).toFixed(2);
}

export const converter_RUB_S = (price, curse) => {
    return (price/curse).toFixed(2);
}

export  const getCurrentDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    var hours = String(today.getHours());
    var min = String(today.getMinutes());
    today = dd + '/' + mm + '/' + yyyy +" "+ hours+":"+min;
    return today;
}
export const getHumanDate = (data) => {
    const date = new Date(data);
    const formattedDate = date.toLocaleString('ru-RU');
    return formattedDate;
}





