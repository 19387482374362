
import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../../index";
import {createPost} from "../../../http/PostavAPI";
const BrandUpdateModal = observer((props) => {


    const {settings,user} = useContext(Context);
    const [name, setName] = useState('');




    const closeModalPost = () => {
        props.setOpenUpdate(false);
        user.setShadowOverlay(false)
    }



    return (
        <div className={props.isOpen ? "add_all_modal active" : "add_user_modal"}>
            <div className={"add_all_field_container"}>
                <span className={"form_heading_all"}>Редактировать бренд</span>
                <div className={'field-container_all'}>
                    <span className={'pls_add_all'}>Название бренда</span>
                    <div className={'input_container_add_all'}>
                        <input type='text'
                               value={name ? name : ""}
                               onChange={ e => setName(e.target.value)}
                               className={'add_all_input'}
                        />
                    </div>
                    <span className={"error_message"}>{ props.error ? props.error : "" }</span>
                </div>

                <div className={"wrks_buttons_container"}>
                    <span onClick={e => props.updatePostHandler(props.idUpd,name)} className={"mini-btn-style add"}>Сохранить</span>
                    <span onClick={e => closeModalPost()} className={"mini-btn-style"}>Отмена</span>
                </div>
            </div>
        </div>
    );
});

export default BrandUpdateModal;