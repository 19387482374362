import React, {useContext, useEffect, useState} from 'react';
import "./AuthPage.css";
import InputMask from 'react-input-mask';
import {authUser, login} from "../../http/userAPI";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {useNavigate} from "react-router";

const AuthPage = observer((props) => {

    const {user} = useContext(Context);
    const Navigation = useNavigate()

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [passwordFieldType, setPasswordFieldType] = useState('password');
    const [rememberMe, setRememberMe] = useState(false);
    const [errorText, setErrorText] = useState('');
    useEffect(() => {
        if(user.isAuth) {
            console.log(Navigation)
            Navigation('/adm/user')

        }
    },[user.isAuth])
    const changeTypeField = (passwordFieldType) => {
        if(passwordFieldType === "password") {
            setPasswordFieldType('text');
            return false;
        }
        setPasswordFieldType('password');

    }
    const handleCheckboxChange = () => {
        setRememberMe(!rememberMe);
    };

    const signOn = async (login,password) => {

        if(login.length < 6) {
            setErrorText('Заполните e-mail');
            return false;
        }
        const data =  await authUser(login,password)

        if(data.error) {
            setErrorText(data.error);
            return false;
        }

        if(data.id) {
            setErrorText("");
            user.setUser(data);
            user.setIsAuth(true);
            Navigation('/adm/user');
            window.location.reload();

        }


    }


    return (
        <div className={'authPage'}>
            <div className={'pre-form-container'}>
                <span className={"heading_auth_page"}>Авторизация</span>
                <div className={'auth-form'}>
                    <div className={'field-container'}>
                        <span className={'pls'}>E-mail</span>
                        <InputMask
                            mask=""
                            maskChar=""
                            value={login ? login : ""}
                            className={"field_auth"}
                            onChange={e => setLogin(e.target.value)}
                            alwaysShowMask={true}
                        />
                    </div>
                    <div className={'field-container'}>
                        <span className={'pls'}>Пароль</span>
                        <div className={'input_container'}>
                            <input type={passwordFieldType}
                                   value={password ? password : ""}
                                   onChange={ e => setPassword(e.target.value)}
                                   className={'field_auth'}
                            />
                            <div className={'glase'}
                                 onClick={ e => changeTypeField(passwordFieldType)}
                            >
                                <img src="../show.png" alt=""/>
                            </div>
                        </div>

                    </div>
                    <span className={'error_login'}>{errorText ? errorText : ""}</span>
                    <div className={"container_assistent"}>
                        <div className={'fsv_container'}>

                        </div>
                        <div className={"fogot_password"}>
                            <span>Забыли пароль?</span>
                        </div>

                    </div>

                    <span
                        onClick={ e => signOn(login,password)}
                        className={"login_button_form"}>
                        Авторизироваться
                    </span>
                </div>
            </div>
        </div>
    );
});

export default AuthPage;

