import {$authHost,$host} from "./index";



export const createBrand = async (name) => {
    const {data} = await $authHost.post('api/brand/create', {name})
    return data;
}

export const deleteBrand = async (id) => {
    const {data} = await $authHost.post('api/brand/delete', {id})
    return data;
}

export const changeActiveBrand = async (id) => {
    const {data} = await $authHost.post('api/brand/changeactivebrand', {id})
    return data;
}

export const updateBrand = async (id,name) => {
    const {data} = await $authHost.post('api/brand/update', {id,name})
    return data;
}

export const fetchAllBrand = async (limit,offset,getCount,searchRoleParam,searchQuery) => {
    const {data} = await $authHost.post('api/brand/fetchallbrand',{limit,offset, getCount,searchRoleParam,searchQuery});

    return data;
}

export const fetchDeactiveBrand = async () => {
    const {data} = await $authHost.post('api/brand/fetcdeactivebrand');

    return data;
}
