import {$authHost,$host} from "./index";






export const fetchAllSet = async (limit,offset,getCount,searchRoleParam,searchQuery) => {
    const {data} = await $authHost.post('api/set/fetchAll',{limit,offset, getCount,searchRoleParam,searchQuery});

    return data;
}

export const createSet = async (name,aromats,ml) => {
    const {data} = await $authHost.post('api/set/create',{name,aromats,ml});
    return data;
}

export const editeSet = async (id,name,aromats,ml) => {
    const {data} = await $authHost.post('api/set/edit',{id,name,aromats,ml});
    return data;
}

export const changeActiveSet = async (id) => {
    const {data} = await $authHost.post('api/set/changeactive', {id})
    return data;
}

export const deleteSet = async (id) => {
    const {data} = await $authHost.post('api/set/deleteset', {id})
    return data;
}

export const getFullSetInfo = async (id) => {
    const {data} = await $authHost.post('api/set/getfullsetinfo', {id})
    return data;
}
export const getFullSetInfoForEdit = async (id) => {
    const {data} = await $authHost.post('api/set/getfullsetinfoforedit', {id})
    return data;
}

export const setArticulTable = async (id,dataItem) => {
    const {data} = await $authHost.post('api/set/setarticultable', {id,dataItem})
    return data;
}