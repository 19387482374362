import React, {useContext, useEffect, useState} from 'react';
import {Context} from "../../index";
import {useLocation} from "react-router";
import {Link, useParams} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {
    changeActiveAroma,
    changeAromaName, changeButtonStatus,
    getAromaAndEntrance,
    getHistoryAromaById,
    updateBrandByAromaId
} from "../../http/AromaApi";
import "./AromaDetailPage.css";
import PostavshArea from "../../components/PostashArea/PostavshArea";
import HistoryUse from "../../components/historyUse/HistoryUse";
import DetailAreaAroma from "../../components/DetailAreaAroma/DetailAreaAroma";
import AnswerModalDeactive from "../../components/UI/AnswerModalDelete/AnswerModalDeactive";
import {fetchAllBrand} from "../../http/BrandApi";
import SuccessModal from "../../components/UI/SuccessModal/SuccessModal";
import Preloader from "../../components/UI/preloader/Preloader";
import {getCurrentDate} from "../../http/SupportFunctions";

const AromaDetailPage = observer(() => {


    const {settings,user} = useContext(Context);
    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState();
    const [refresh, setRefresh] = useState(false);

    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)



    let page = +queryParams.get('page');

    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }
    let id = params.id;
    const limit = 50;
    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0)


    const [aroma,setAroma] = useState(null);
    const [postavki,setPostavki] = useState(null);
    const [brandsArr,setBrandsArr] = useState(null);
    const [brands,setBrands] = useState(null);

    const [selectedBrandSwitch, setSelectedBrandSwitch] = useState();

    const [openBrand,setOpenBrand] = useState(false);
    const [searchBrandQuery,setSearchBrandQuery] = useState("");

    useEffect(() => {
        fetchAllBrand(999,1,true,searchRoleParam,searchBrandQuery).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }
            setBrandsArr(response.rows);

        })
    },[searchBrandQuery])

    const [history,setHistory] = useState(null);

    const [linkYandexSearch, setLinkYandexSearch] = useState(null);


    useEffect(() => {
        console.log(id)


        getAromaAndEntrance(id).then(response => {
            if(response.error) {
                return false;
            }
            setAroma(response);
            if(response.brand) {
                setSelectedBrandSwitch(response.brand);
            }

            getHistoryAromaById(id).then(response => {
                if(response.rows) {
                    setHistory(response.rows);
                }

            });
            setAroma(response);
            console.log("Весь аромат со вложенностями")
            console.log(response);
            console.log("ПОПОЛНЕНИЯ")
            setPostavki(response.entrances)

            let nameAroma = response.name;
            let nameBrand = response.brand.name;

            let arr = nameAroma.split(' ');
            let brandArr = nameBrand.split(' ');
            let searchParamName = arr.join('+');
            let searchParamBrand = brandArr.join('+');
            let globalSearchParam = searchParamBrand+"+"+searchParamName;
            setLinkYandexSearch(`https://yandex.ru/search/?text=${globalSearchParam}&lr=971`)
            // console.log(linkYandexSearch)
            //
        })
    },[refresh])



    const [opAnswModal,setOpAnswModal] = useState(false);
    const [textAnswModal, setTextAnswModal] = useState('');
    const [btnText, setBtnText] = useState('');
    const [idAnswModal, setIdAnswModal] = useState('');
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [successText, setSuccessText] = useState('');

    const openAnswModalDeactive = (item) => {
        let text = aroma.isActive ? "Деактивировать" : "Активировать";

        setBtnText(text)
        setTextAnswModal(`Вы точно хотите ${text} данный аромат?`);
        setIdAnswModal(item.id)
        setOpAnswModal(true)
        user.setShadowOverlay(true);
    }

    const closeAnswModal = () => {
        setOpAnswModal(false);
        user.setShadowOverlay(false);
    }



    const [whoPage,setWhoPage] = useState('detail');


    const [updateName, setUpdateName] = useState(false);
    const [updateNotes, setUpdateNotes] = useState(false);
    const [errorResponseName,setErrorResponseName] = useState("");
    const deactiveAroma = async () => {
       const response = await changeActiveAroma(aroma.id);
        if(response.message) {
            setOpAnswModal(false);
            // user.setShadowOverlay(false);
            // setRefresh(!refresh);
            setOpenSuccessModal(true);
            setSuccessText(response.text)
            }
    }






    const saveAromaName = async  (id,name,notes) => {
        let searchName = aroma.brand.name+" - "+name;
       const response = await changeAromaName(id,name,notes,searchName);
        if(response.message) {
            // setOpAnswModal(false);
            // user.setShadowOverlay(false);
            setRefresh(!refresh);
            setUpdateName(false)
            setUpdateNotes(false)
            setErrorResponseName("");
            setOpenSuccessModal(true);
            setSuccessText("Название и пометки изменены успешно");
            user.setShadowOverlay(true);
        }
        if(response.error) {
            setErrorResponseName(response.error)
        }
    }

    const selectBrandHandler = (item) => {
        setSelectedBrandSwitch(item)
        // setOpenBrand(false);
    }


    const saveBrandHandler = (idAroma, brand) => {
        let searchName = brand.name+" - "+aroma.name;
        updateBrandByAromaId(id,brand,searchName).then(response => {
            if(response.message) {
                setOpenBrand(false)
                setSuccessText("Бренд обновлен успешно!")
                user.setShadowOverlay(true)
                setOpenSuccessModal(true)
            }
        })
    }


    useEffect(() => {

        if(user.shadowOverlay === false) {
            setOpAnswModal(false)
            setOpenSuccessModal(false)
        }
    },[user.shadowOverlay])


    const changeButtonStatusHandler = async (id,value) => {
        settings.setPreloader(true)
        let today = getCurrentDate();
        let response = await changeButtonStatus(id,value,today);
        console.log(response)
        if(response.success) {
            settings.setPreloader(false);
            setRefresh(!refresh);
        }
    }


    if(user.getUserRights && user.getUserRights.items_aroma_view) {

        return (
            <div className={"detail_aroma_page"}>

                <a target={"_blank"} className={"yandex_link_button"} href={linkYandexSearch ? linkYandexSearch : "" }>Я</a>

                <AnswerModalDeactive isOpen={opAnswModal} text={textAnswModal} buttonText={btnText} function={deactiveAroma} id={idAnswModal} close={closeAnswModal}  />
                <SuccessModal isOpen={openSuccessModal} text={successText} />


                <div className={"controll_panel_aroma"}>
                    <div className={"top_line_controll_items"}>
                        <div className="wrap_for_aroma_and_brand">
                            <div className={"brand_switch_container"}>
                                <div className={"wrap_controller_s"}>
                                    <span className={"strong span_fix_text"}>Бренд:  </span>
                                    <span className={"span_fix_text onclick"} onClick={e => setOpenBrand(!openBrand)}>{selectedBrandSwitch ? selectedBrandSwitch.name : ""}</span>
                                    <span onClick={e => saveBrandHandler(aroma.id, selectedBrandSwitch)} className={openBrand ? "upd_brand active" : "upd_brand"}>Сохранить бренд</span>
                                </div>
                                <div className={openBrand ? "search_brand_wrap active" : "search_brand_wrap"}>
                                    <div className={"aroma_wrapp_for_search"}>
                                        <div className={'seach_container'}>
                                            <input
                                                className={"search_input"}
                                                value={searchBrandQuery ? searchBrandQuery : ""}
                                                onChange={ e => setSearchBrandQuery(e.target.value)}
                                                placeholder={"Поиск..."} type="text"/>
                                            <div className={"abs_lupa"}>
                                                <img src="/lupa.png" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"brands_list"}>
                                        {
                                            brandsArr && brandsArr.length ? brandsArr.map((item, index) =>
                                                <div onClick={e => selectBrandHandler(item)} className={"wrapper_for_select_brand"} key={index}>
                                                    <span  className={"one_brand_item"}>{item.name}</span>
                                                </div>
                                            ) : ''
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>

                        {
                            user.getUserRights.edit_aroma_name &&
                            <div className={ !updateName ? "pancel_container" : "pancel_container active"}  onClick={e => setUpdateName(!updateName)}>
                                <img src="/pancel.png" alt=""/>
                            </div>
                        }

                        <div onClick={e => saveAromaName(aroma.id,aroma.name,aroma.notes)} className={"deactive_aroma no_left"}>Сохранить изменения</div>
                        <Link to={`/adm/aroma/${id}/add`} className={"add_postuplenie"}>Добавить поступление</Link>
                        <span className={"err_message_name"}>{errorResponseName ? errorResponseName : ""}</span>
                        <div className={ aroma && aroma.isActive ? "deactive_aroma on" : "deactive_aroma off" } onClick={e => openAnswModalDeactive(aroma.id)} >{ aroma && aroma.isActive ? "Деактивировать" : "Активировать" }</div>
                    </div>
                    <div className={"fields_wrapper_info_support"}>
                        {
                            updateName &&
                            <input readOnly={ !updateName ? true : false} value={aroma ? aroma.name : ""} className={ updateName ? "aroma_name_title input" : "aroma_name_title"} onChange={e => setAroma({...aroma, name:e.target.value})}/>

                        }
                        {
                            !updateName &&
                            <span className={"one_page_item_title"}>{aroma ? aroma.name : ""}</span>
                        }

                        <div className={"wrapper_for_notes_buttons"}>
                            <div className={"wrapper_for_title_notes"}>
                                <div className={ !updateNotes ? "pancel_container notes" : "pancel_container notes active"}  onClick={e => setUpdateNotes(!updateNotes)}>
                                    <img src="/pancel.png" alt=""/>
                                </div>
                                <span className={"notes_title"}>Пометки</span>

                            </div>

                            <textarea readOnly={ !updateNotes ? true : false} value={aroma ? aroma.notes : ""} className={ updateNotes ? "textarea_box input" : "textarea_box"} onChange={e => setAroma({...aroma, notes:e.target.value})}/>
                        </div>
                    </div>


                    <div className={"tabs_for_swap_page_content"}>
                        <span onClick={e => setWhoPage('detail')} className={whoPage == "detail" ? "tabs_item active" : "tabs_item" }>Данные об аромате</span>
                        <span onClick={e => setWhoPage('history-post')} className={whoPage == "history-post" ? "tabs_item active" : "tabs_item" }>История поступления</span>
                        <span onClick={e => setWhoPage('history-use')} className={whoPage == "history-use" ? "tabs_item active" : "tabs_item" }>История пользования</span>
                        {
                            aroma &&
                            <div className={"status_buttons_container"}>
                                <div className={"flex-container-buttons-wrap"}>
                                    <span onClick={e => changeButtonStatusHandler(aroma.id,"buy")} className={aroma.statusButton ==  "buy" ? "standart_status_button active" : "standart_status_button"}>Заказали</span>
                                    <span onClick={e => changeButtonStatusHandler(aroma.id,"search")} className={aroma.statusButton ==  "search" ? "standart_status_button active" : "standart_status_button"}>В поиске</span>
                                    <span onClick={e => changeButtonStatusHandler(aroma.id,"no_find")} className={aroma.statusButton ==  "no_find" ? "standart_status_button active" : "standart_status_button"}>Пока нигде нет</span>
                                    <span onClick={e => changeButtonStatusHandler(aroma.id,"no_buy")} className={aroma.statusButton ==  "no_buy" ? "standart_status_button active" : "standart_status_button"}>Больше не покупаем</span>
                                </div>
                                {
                                    aroma.statusChangeDate &&
                                    <div className={"time_container_buttons"}>
                                        { aroma.statusChangeDate}
                                    </div>
                                }


                            </div>
                        }
                    </div>
                </div>


                <DetailAreaAroma aroma={aroma} history={history} activePage={whoPage} active={whoPage == "detail" ? true : false} />
                <PostavshArea activePage={whoPage} data={postavki} active={whoPage == "history-post" ? true : false}/>
                <HistoryUse activePage={whoPage} active={whoPage == "history-use" ? true : false} />


            </div>
        )

    } else {
        return (
            <div className={"close_page_for_you"}>
                <span>Доступ закрыт</span>
            </div>
        )
    }

});

export default AromaDetailPage;
