import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import './AromaPage.css';


import {Link, Route, Routes, useParams} from "react-router-dom";
import {useLocation} from "react-router";
import {changeActiveBrand, fetchAllBrand, fetchDeactiveBrand} from "../../http/BrandApi";
import {changeActiveAroma, fetchAllAroma} from "../../http/AromaApi";
import SuccessModal from "../../components/UI/SuccessModal/SuccessModal";
import AnswerModalDeactive from "../../components/UI/AnswerModalDelete/AnswerModalDeactive";
import Pagination from "../../components/UI/Paginstion";


const AromaPage = observer((props) => {



    const {settings,user} = useContext(Context);
    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState();

    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')


    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }

    const limit = 50;
    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0)
    const [aromaArray, setAromaArray] = useState(null);

    ///DEactiv
    const [opAnswModal,setOpAnswModal] = useState(false);
    const [textAnswModal, setTextAnswModal] = useState('');
    const [btnText, setBtnText] = useState('');
    const [idAnswModal, setIdAnswModal] = useState('');
    const [openAddPostModal, setOpenAddPostModal] =  useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [successText, setSuccessText] = useState('');
    const [errorAddPost, setErrorAddPost] = useState('');
    const [refresh,setRefresh] = useState(false);
    useEffect(() => {
        fetchAllAroma(limit,(page-1)*limit,true,searchRoleParam,searchQuery).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }
            setCount(response.count)
            setAromaArray(response.rows);
            console.log(response.rows)
        })

    }, [page,searchRoleParam,searchQuery,refresh])


    const openAnswModalDeactive = (item) => {
        let text = item.isActive ? "Деактивировать" : "Активировать";
        setBtnText(text)
        setTextAnswModal(`Вы точно хотите ${text} данный аромат?`);
        setIdAnswModal(item.id)
        setOpAnswModal(true)
        user.setShadowOverlay(true);
    }
    const closeAnswModal = () => {
        setOpAnswModal(false);
        user.setShadowOverlay(false);
    }

  useEffect(() => {
      // console.log(9999999999999999)
      if(user.shadowOverlay == false) {
          setOpAnswModal(false)
          setOpenSuccessModal(false)
      }

  },[user.shadowOverlay])


    const deactiveAromaHandler = async (fun,id) => {

            let response = await changeActiveAroma(id);
            console.log(response)
            if(response.message) {
                let text = response.text;
                setSuccessText(`Аромат ${text} успешно`)
                setOpAnswModal(false)
                setOpenSuccessModal(true)
                setRefresh(!refresh)
                if(!user.shadowOverlay){
                    user.setShadowOverlay(true)
                }
            }

            if(response.error) {
                return false;
            }

    }

    if(user.getUserRights && user.getUserRights.items_aroma_view) {
        return (
            <div  className={"main_page_container_post"}>
                <SuccessModal isOpen={openSuccessModal} text={successText} />
                <AnswerModalDeactive isOpen={opAnswModal} text={textAnswModal} buttonText={btnText} function={deactiveAromaHandler} id={idAnswModal} close={closeAnswModal}  />


                <div className={"post_container"}>
                    <div className={"active_aroma"}>
                        <div className={'heading_urp_block'}>
                            <span className={"name_page"}>Ароматы</span>
                            <div className={'seach_container'}>
                                <input
                                    className={"search_input"}
                                    value={searchQuery ? searchQuery : ""}
                                    onChange={ e => setSearchQuery(e.target.value)}
                                    placeholder={"Поиск..."} type="text"/>
                                <div className={"abs_lupa"}>
                                    <img src="/lupa.png" alt=""/>
                                </div>
                            </div>
                            {
                                user.getUserRights.add_aroma &&
                                <Link to={"/adm/aroma/add"} className={"add_new_btn aroma"}>
                                    Добавить товар
                                </Link>
                            }

                            <span className={"add_new_btn aroma"}>Скачать прайс</span>

                        </div>
                        <div className={"heading_line_aroma"}>
                            <span className={"heading_number"}>№</span>
                            <span className={"heading_line_aroma_name"}>Наименование</span>
                            <span className={"heading_line_aroma_work"}>Действие</span>
                        </div>
                        {
                            aromaArray ? aromaArray.map((item, index) =>
                                <div className={"heading_line_aroma"} key={index}>
                                    <span className={"heading_number"}>{index+1}</span>
                                    <Link to={`/adm/aroma/${item.id}`} className={'name_line_aroma_m'}>{item.brand.name + " - " +item.name}</Link>
                                    <div className={"aroma_container_func"}>
                                        <span onClick={e => openAnswModalDeactive(item)} className={item.isActive ? "bnt_post" : "bnt_post deact"}>{item.isActive ? "Деактивировать" : "Активировать"}</span>
                                    </div>
                                </div>
                            ) : ''
                        }
                        <Pagination page={page} limit={limit} count={count}/>

                    </div>

                </div>
            </div>
        );
    } else {
        return (
            <div className={"close_page_for_you"}>
                <span>Доступ закрыт</span>
            </div>
        )
    }

});

export default AromaPage;
