import {$authHost,$host} from "./index";
import {jwtDecode} from "jwt-decode";



export const registration = async (login,password,name, role) => {
    const response = await $host.post('api/user/registration', {login,password,name, role})
    return response;
}


export const authUser = async (login,password) => {
    const {data} = await $host.post('api/user/loginUser', {login,password})

    if (!data.token) {
        return data;
    }

    localStorage.setItem('token', data.token)
    console.log(localStorage)
    return jwtDecode(data.token);
}


export const check = async () => {
    const {data} = await $authHost.get('api/user/auth')
    if(data.token) {
        localStorage.setItem('token', data.token)
        return jwtDecode(data.token);
    }

}
export const deleteUser = async (id) => {
    const {data} = await $authHost.post('api/user/userremove', {id});
    console.log(data)
    if(data) {
        return data;
    }
}
export const changeActiveUser = async (id) => {
    const {data} = await $authHost.post('api/user/changeactive', {id});
    if(data) {
        return data;
    }
}


export const updateUserRights = async (id, value) => {
    const {data} = await $authHost.post('api/user/updateuserrights', {id, value});
    if(data) {
        return data;
    }
}

export const getUser = async (id) => {
    const {data} = await $authHost.post('api/user/getuser', {id});
    if(data) {
        return data;
    }
}
export const changeNumberGetCode = async (newNumber,lengthNumber,currentUser) => {
    const data = await $authHost.post('api/user/changenumber', {newNumber,lengthNumber,currentUser})
    if(data) {
        return data;
    }
    return false;
}

export const isComparePassword = async (code, userId, newNumber, newCountry) => {
    const {data} = await $authHost.post('api/user/comparepassword', {code, userId, newNumber, newCountry})
    if(data.token) {
        localStorage.setItem('token', data.token)
        return jwtDecode(data.token);
    }
    return false;
}

export const saveUserInfo = async (userFields,user) => {

    const {data} = await $authHost.post('api/user/saveuserinfo',{userFields,user})

    return data;

}

export const adminLogin = async (userLogin,password) => {
    const {data} = await $authHost.post('api/user/adminlogin',{userLogin,password})
    if(data.token) {
        localStorage.setItem('token', data.token)
        return jwtDecode(data.token);
    }
    return data;
}


export const getOrdersByUserId = async (userId) => {
    const {data} = await $authHost.post('api/user/getordersbyuserid',{userId})
    return  data;
}

export const fetchAllUsers = async (limit,offset,getCount,searchRoleParam,searchQuery) => {
    const {data} = await $authHost.post('api/user/fetchallusers',{limit,offset, getCount,searchRoleParam,searchQuery});

    return data;
}
export const saveAdmUser = async (userField) => {
    const {data} = await $authHost.post('api/user/saveadmuser',{userField});

    return data;
}

export const parserUserCsv = async (sortField,sortParam) => {
    const data = await $authHost.post('api/user/parseuserscsv',{sortField,sortParam})
    return data;
}

export const findUserByNumber = async (number) => {
    const {data} = await $host.post('api/user/finduserbyid',{number})
    return data;
}

export const getСheckingUserOrder = async (userId) => {
    const {data} = await $authHost.post('api/user/getcheckinguserorder',{userId})
    return  data;
}
