import React from 'react';
import {observer} from "mobx-react-lite";
import {Route, Routes} from "react-router-dom";
import UserPage from "./UserPage";
import PostavshikiPage from "../postavshiki/PostavshikiPage";
import BrandPage from "../brandPage/BrandPage";
import AromaPage from "../AromaPage/AromaPage";
import AddAromaPage from "../AddAromaPage/AddAromaPage";
import AromaDetailPage from "../aromaDetailPage/AromaDetailPage";
import AddNewEntrance from "../addNewEntrance/AddNewEntrance";
import UserRights from "../userRights/UserRights";
import Sets from "../sets/Sets";
import AddSets from "../addSets/AddSets";
import DetailSetPage from "../detailSetPage/DetailSetPage";
import OstatkiDash from "../OstatkiDash/OstatkiDash";
import TempDashboadr from "../TempDashboard/TempDashboadr";
import AllAromaInfoDash from "../AllAromaInfoDash/AllAromaInfoDash";
import NewAromaDiagramm from "../NewAromaDiagramm/NewAromaDiagramm";
import EntranceDiagramm from "../EntranceDiagramm/EntranceDiagramm";
import NoArtuculPage from "../NoArtPage/NoArtuculPage";
import NoLinksPage from "../NoLinksPage/NoLinksPage";
import HistoryUse from "../../components/historyUse/HistoryUse";
import AromaHistoryPage from "../AromaHistoryPage/AromaHistoryPage";
import AllParamsPage from "../allParamsPage/AllParamsPage";
import EditSet from "../editSet/editSet";
import WrongItemsPage from "../wrongItemsPage/WrongItemsPage";

const RouteAdminPage = observer(() => {
    return (
        <div className={"prosl_custom"}>
            <Routes>
                {/*<Route path="/" element={<UserPage/>} />*/}
                <Route path="/user/rights/:id/" element={<UserRights/>} />
                <Route path="/user/*" element={<UserPage/>} />
                <Route path="/post/*" element={<PostavshikiPage/>} />
                <Route path="/brand/*" element={<BrandPage/>} />
                <Route path="/aroma/" element={<AromaPage/>} />
                <Route path="/aroma/add/" element={<AddAromaPage/>} />
                <Route path="/aroma/:id/" element={<AromaDetailPage/> } />
                <Route path="/aroma/:id/add" element={<AddNewEntrance/> } />
                <Route path="/aroma/:id/history" element={<AromaHistoryPage/> } />

                <Route path="/sets/*" element={<Sets/> } />
                <Route path="/sets/add/" element={<AddSets/> } />
                <Route path="/sets/edit/:id" element={<EditSet/> } />
                <Route path="/sets/:id/" element={<DetailSetPage/> } />
                <Route path="/dashboard/ost/" element={<OstatkiDash/> } />
                <Route path="/dashboard/temp/" element={<TempDashboadr/> } />
                <Route path="/dashboard/info/" element={<AllAromaInfoDash/> } />
                <Route path="/dashboard/newaromadiagramm/" element={<NewAromaDiagramm/> } />
                <Route path="/dashboard/entrancediagramm/" element={<EntranceDiagramm/> } />
                <Route path="/noart/" element={<NoArtuculPage/> } />
                <Route path="/nolink/" element={<NoLinksPage/> } />
                <Route path="/allparams/" element={<AllParamsPage/> } />
                <Route path="/wrongitems/" element={<WrongItemsPage/> } />




                {/*<Route path="/color-manager/" element={<ColorManager/>}/>*/}
                {/*<Route path="/size-manager/" element={<SizeManager/>}/>*/}
                {/*<Route path="/landry-manager/" element={<Landry/>}/>*/}
                {/*<Route path="/content/home/" element={<AdmHomePage/>}/>*/}
                {/*<Route path="/content/lookbook/" element={<AdmLookbook/>}/>*/}
                {/*<Route path="/content/lookbook/:id" element={<OneLook/>}/>*/}
                {/*<Route path="/content/reviews/" element={<AdminReviewsPage/>}/>*/}
                {/*<Route path="/content/vacancy/" element={<Vacancy/>}/>*/}
                {/*<Route path="/content/shop/" element={<AdminShopPage/>}/>*/}
                {/*<Route path="/content/contacts/" element={<AdminContactsPage/>}/>*/}
                {/*<Route path="/content/faq/" element={<AdmFqa/>}/>*/}
                {/*<Route path="/content/payment/" element={<AdmPayment/>}/>*/}
                {/*<Route path="/content/delivery/" element={<AdmDelivery/>}/>*/}
                {/*<Route path="/content/about/" element={<AdmAbout/>}/>*/}
                {/*<Route path="/content/404/" element={<AdmPageNotFound/>}/>*/}
                {/*<Route path="/settings/city/" element={<CityManager/>}/>*/}
                {/*<Route path="/settings/category/" element={<CategoryManager/>}/>*/}
                {/*<Route path="/orderlist/:custom_order_id/" element={<OneOrderPage />} />*/}
                {/*<Route path="/orderlist/" element={<OrdersList/>} />*/}
                {/*<Route path="/settings/headerandfooter/" element={<HeaderAndFooterSettings/>}/>*/}
                {/*<Route path="/settings/delivery/" element={<DeliveryManager/>}/>*/}
                {/*<Route path="/settings/social/" element={<SocialManager/>}/>*/}
                {/*<Route path="/settings/promo/" element={<Promocode/>}/>*/}
                {/*<Route path="/settings/order_statuses/" element={<OrderStatus/>}/>*/}
                {/*<Route path="/settings/modal_controll/" element={<ModalPage/>}/>*/}
                {/*<Route path="/settings/sitemap/" element={<Sitemap/>}/>*/}
                {/*<Route path="/settings/redirects/" element={<Redirects/>}/>*/}
                {/*<Route path="/settings/doly/" element={<DolyamiSettingsPage/>}/>*/}
                {/*<Route path="/returns/" element={<Returns/>}/>*/}
                {/*<Route path="/returns/:id" element={<ReturnItemPage />}/>*/}
            </Routes>
        </div>
    );
});

export default RouteAdminPage;
