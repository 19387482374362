import React from 'react';
import "./AnswerModalDeactive.css";
const AnswerModalDeactive = (props) => {
    return (
        <div className={props.isOpen ? "add_user_modal active" : "add_user_modal"}>
            <div className={"success_add_user_container"}>
                <span>{props.text}</span>
                <div className={"buttons_container_answ"}>
                    <span onClick={e => props.function('deactiv', props.id)} className={"yes_button"}>{props.buttonText}</span>
                    <span onClick={e => props.close()} className={"cancel_button"}> Отмена </span>
                </div>
            </div>
        </div>
    );
};

export default AnswerModalDeactive;