import {$authHost,$host} from "./index";



export const createPost = async (name) => {
    const {data} = await $authHost.post('api/post/create', {name})
    return data;
}

export const deletePost = async (id) => {
    const {data} = await $authHost.post('api/post/delete', {id})
    return data;
}

export const changeActive = async (id) => {
    const {data} = await $authHost.post('api/post/changeactive', {id})
    return data;
}

export const updatePost = async (id,name) => {
    const {data} = await $authHost.post('api/post/update', {id,name})
    return data;
}

export const fetchAllPost = async (limit,offset,getCount,searchRoleParam,searchQuery) => {
    const {data} = await $authHost.post('api/post/fetchallpost',{limit,offset, getCount,searchRoleParam,searchQuery});

    return data;
}

export const fetchDeactivePosts = async () => {
    const {data} = await $authHost.post('api/post/fetcdeactivepost');

    return data;
}
