import React, {useContext, useEffect, useState} from 'react';
import "./tempDash.css"
import {observer} from "mobx-react-lite";
import {fetchAllSet} from "../../http/setApi";
import {useLocation} from "react-router";
import {Link, useParams} from "react-router-dom";
import {fetchRashodByTime, getOstatkiByFilter} from "../../http/AromaApi";
import Pagination from "../../components/UI/Paginstion";
import DashboardControllerPage from "../../components/DashboardControllerPage/DashboardControllerPage";
import {Context} from "../../index";


const TempDashboard = observer(() => {



    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState(50000);

    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')


    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }

    const [allItems,setAllItems] = useState(0)
    const [postavArray, setPostavArray] = useState(null);
    const [refresh,setRefresh] = useState(false);
    /////

    const limit = 50;
    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0)
    const {settings,user} = useContext(Context);





    const [sortSelector, setSortSelector] = useState("DESC");
    const [openSortSelector, setOpenSortSelector] = useState(false)

    const supportHandlerChange = (param) => {
        setSortSelector(param);
        setOpenSortSelector(false)
    }


    const supportDataChange = (param,name) => {
        setDataInterval(param)
        setDataNameSelect(name)
        setOpenDateSelector(false)
    }

    const [dataNameSelect,setDataNameSelect] = useState("За последний месяц")
    const [dateInterval,setDataInterval] = useState("30d");
    const [openDateSelector, setOpenDateSelector] = useState(false);


    useEffect(() => {

        console.log("date"+dateInterval)
        console.log("sort" + sortSelector)
        fetchRashodByTime(limit,(page-1)*limit,true,searchRoleParam,searchQuery,dateInterval,sortSelector).then(response => {
            setCount(response.count);
            setAllItems(response.data);
        })

    }, [searchQuery,page,sortSelector,dateInterval]);


    return (
        <div className={"all_ml_ost_page"}>
            <span className={"name_page_default"}>Дашборд - Темп расхода</span>
            <DashboardControllerPage page={"temp"} />

            {
                user.getUserRights && user.getUserRights.temp_rashoda_aromata &&
                <div className={"wrapper_for_selectors"}>
                    <div className={ openSortSelector ? "rashod_controller active" : "rashod_controller" }>
                        <span className={"click_span_sel"} onClick={e => setOpenSortSelector(!openSortSelector)}>{sortSelector === "DESC" ? "По убыванию расхода" : "По возрастанию расхода"}</span>
                        <div className={ openSortSelector ? "open_area_controller active" : "open_area_controller" }>
                            <span onClick={e => supportHandlerChange("DESC")} className={"item_zvc"}>По убыванию расхода</span>
                            <span onClick={e => supportHandlerChange("ASC")} className={"item_zvc"}>По возрастанию расхода</span>
                        </div>
                    </div>

                    <div className={openDateSelector ? "rashod_controller active" : "rashod_controller"}>
                        <span className={"click_span_sel"} onClick={e => setOpenDateSelector(!openDateSelector)}>{dataNameSelect}</span>
                        <div className={ openDateSelector ? "open_area_controller active" : "open_area_controller" }>
                            <span onClick={e => supportDataChange("30d","За последний месяц")} className={"item_zvc"}>За последний месяц</span>
                            <span onClick={e => supportDataChange("90d","За 3 месяца")} className={"item_zvc"}>За 3 месяца</span>
                            <span onClick={e => supportDataChange("183d","За 6 месяцев")} className={"item_zvc"}>За 6 месяцев</span>
                            <span onClick={e => supportDataChange("365d","За 1 год")} className={"item_zvc"}>За 1 год</span>
                            <span onClick={e => supportDataChange("730d","За 2 года")} className={"item_zvc"}>За 2 года</span>
                            <span onClick={e => supportDataChange("1095d","За 3 года")} className={"item_zvc"}>За 3 года</span>
                        </div>
                    </div>
                </div>
            }


            {
                user.getUserRights && user.getUserRights.temp_rashoda_aromata &&

                <div className={"wrapper_for_list_rashod"}>
                    <div className={"one_line_rash strong"}>
                        <div className={"rash_name"}>Название аромата</div>
                        <div className={"rash_value"}>Расход, мл</div>
                    </div>
                    {
                        allItems ? allItems.map((item, index) =>
                            <div className={"one_line_rash"}>
                                <Link to={`/adm/aroma/${item.id}`} className={"rash_name"} key={index}>
                                    {item.name}
                                </Link>
                                <div className={"rash_value"}>{item.rashod}</div>
                            </div>
                        ) : ''
                    }
                </div>
            }



            {
                user.getUserRights && user.getUserRights.temp_rashoda_aromata &&

                <div className={"custom_pag_container"}>
                    <Pagination page={page} limit={limit} count={count}/>
                </div>
            }


            {
                user.getUserRights && !user.getUserRights.temp_rashoda_aromata &&

                <div className={"close_page_for_you"}>
                    <span>Доступ закрыт</span>
                </div>
            }





        </div>
    );
});

export default TempDashboard;


