import React, {useContext, useEffect, useState} from 'react';
import "./newaromadiagramm.css"
import {observer} from "mobx-react-lite";
import {fetchAllSet} from "../../http/setApi";
import {useLocation} from "react-router";
import {Link, useParams} from "react-router-dom";
import {
    fetchRashodByTime,
    GetAllAromaInformation,
    GetAromaItemsDiagramm,
    getOstatkiByFilter
} from "../../http/AromaApi";
import Pagination from "../../components/UI/Paginstion";
import DashboardControllerPage from "../../components/DashboardControllerPage/DashboardControllerPage";
import {set} from "mobx";
import Chart from "../../components/UI/Chart/Chart";
import {DataArray} from "../../utils/consts";
import {Context} from "../../index";


const NewAromaDiagramm = observer(() => {


    const [aromaData,setAromaData] = useState(null)
    const [selectData,setSelectData] = useState("2024");
    const [openSelector, setOpenSelector] = useState(false);
    const {settings,user} = useContext(Context);

    useEffect(() => {
        GetAromaItemsDiagramm(selectData).then(response => {
            console.log(response)
            if(response.data) {
                setAromaData(response.data)
            }
            console.log(response)
        })
    },[selectData])

    const selectAndClose = (item) => {
        setSelectData(item)
        setOpenSelector(false)
    }


    return (
        <div className={"all_ml_ost_page"}>
            <span className={"name_page_default"}>График новых ароматов</span>

            <DashboardControllerPage page={"newaromadiagramm"} />
            {
                user.getUserRights && user.getUserRights.dash_new_aroma &&
                <div className={openSelector ? "selected_data_container active" : "selected_data_container"}>
                    <span onClick={e => setOpenSelector(!openSelector)}>{selectData}</span>
                    <div className={"selected_data_menu"}>
                        {
                            DataArray ? DataArray.map((item, index) =>
                                <span  className={"select_data_item"} key={index} onClick={e => selectAndClose(item)}>{item}</span>
                            ) : ''
                        }
                    </div>
                </div>
            }

            {
                user.getUserRights && user.getUserRights.dash_new_aroma &&
                <Chart dataItems={aromaData} title={"Новые ароматы"}/>
            }

            {
                user.getUserRights && !user.getUserRights.dash_new_aroma &&

                <div className={"close_page_for_you"}>
                    <span>Доступ закрыт</span>
                </div>
            }



        </div>
    );
});

export default NewAromaDiagramm;




