import React, {useContext, useEffect, useState} from 'react';
import "./ostatki.css"
import {observer} from "mobx-react-lite";
import {fetchAllSet} from "../../http/setApi";
import {useLocation} from "react-router";
import {Link, useParams} from "react-router-dom";
import {getOstatkiByFilter} from "../../http/AromaApi";
import Pagination from "../../components/UI/Paginstion";
import DashboardControllerPage from "../../components/DashboardControllerPage/DashboardControllerPage";
import {Context} from "../../index";


const OstatkiDash = observer(() => {



    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState(50000);
    const {settings,user} = useContext(Context);

    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')


    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }

    const [allItems,setAllItems] = useState(0)
    const [postavArray, setPostavArray] = useState(null);
    const [refresh,setRefresh] = useState(false);
    /////

    let limit = 50;
    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0)

    useEffect(() => {
        getOstatkiByFilter(limit,(page-1)*limit,true,searchRoleParam,searchQuery).then(response => {
            setCount(response.count);
            setAllItems(response.data);
            if(!response.data.length) {
                page = 0;
            }
            console.log(response)
        })

    }, [searchQuery,page])



        return (
            <div className={"all_ml_ost_page"}>
                <span className={"name_page_default"}>Дашборд - Остатки</span>
                <DashboardControllerPage page={"ost"} />

                {
                    user.getUserRights && user.getUserRights.dash_ostatki_tovara &&
                    <div className={"search_container_button_options"}>
                        <div onClick={e => setSearchQuery(50)} className={ searchQuery === 50 ? "active btn_sel" : "btn_sel"}>&lt; 50</div>
                        <div onClick={e => setSearchQuery(30)} className={ searchQuery === 30 ? "active btn_sel" : "btn_sel"}>&lt; 30</div>
                        <div onClick={e => setSearchQuery(20)} className={ searchQuery === 20 ? "active btn_sel" : "btn_sel"}>&lt; 20</div>
                        <div onClick={e => setSearchQuery(10)} className={ searchQuery === 10 ? "active btn_sel" : "btn_sel"}>&lt; 10</div>
                        <div onClick={e => setSearchQuery(0)} className={ searchQuery === 0 ? "active btn_sel" : "btn_sel"}>&lt; 0</div>
                    </div>
                }


                {
                    user.getUserRights && user.getUserRights.dash_ostatki_tovara &&
                    <div className={"wrapper_for_list_ost"}>
                        <div className={"item_list_container solid"}> &lt; {searchQuery ? searchQuery : 0}</div>
                        <div className={"item_list_container solid"}>  {count ? "Всего в списке: " +count : "Всего в списке: 0"}</div>

                        {
                            allItems ? allItems.map((item, index) =>
                                <Link to={`/adm/aroma/${item.id}`} className={"item_list_container"} key={index}>
                                    {item.name}
                                </Link>
                            ) : ''
                        }

                    </div>
                }

                {
                    user.getUserRights && user.getUserRights.dash_ostatki_tovara &&

                    <div className={"custom_pag_container"}>
                        <Pagination page={page} limit={limit} count={count}/>
                    </div>
                }

                {
                    user.getUserRights && !user.getUserRights.dash_ostatki_tovara &&

                    <div className={"close_page_for_you"}>
                        <span>Доступ закрыт</span>
                    </div>
                }



            </div>
        );



});

export default OstatkiDash;


