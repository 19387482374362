import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import './wrongItems.css';


import {Link, Route, Routes, useParams} from "react-router-dom";
import {useLocation} from "react-router";
import {changeActiveBrand, fetchAllBrand, fetchDeactiveBrand} from "../../http/BrandApi";
import {changeActiveAroma, fetchAllAroma, fetchWrongItems} from "../../http/AromaApi";
import SuccessModal from "../../components/UI/SuccessModal/SuccessModal";
import AnswerModalDeactive from "../../components/UI/AnswerModalDelete/AnswerModalDeactive";
import Pagination from "../../components/UI/Paginstion";
import {getHumanDate} from "../../http/SupportFunctions";


const WrongItemsPage = observer((props) => {



    const {settings,user} = useContext(Context);
    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState();

    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')


    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }

    const limit = 50;
    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0)
    const [wrongArray, setWrongArray] = useState(null);

    ///DEactiv
    const [opAnswModal,setOpAnswModal] = useState(false);
    const [textAnswModal, setTextAnswModal] = useState('');
    const [btnText, setBtnText] = useState('');
    const [idAnswModal, setIdAnswModal] = useState('');
    const [openAddPostModal, setOpenAddPostModal] =  useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [successText, setSuccessText] = useState('');
    const [errorAddPost, setErrorAddPost] = useState('');
    const [refresh,setRefresh] = useState(false);
    useEffect(() => {

        fetchWrongItems(limit,(page-1)*limit,true,searchRoleParam,searchQuery).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }
            setCount(response.count)
            setWrongArray(response.rows);
            console.log(response.rows)
        })



    }, [page,searchRoleParam,searchQuery,refresh])


    const openAnswModalDeactive = (item) => {
        let text = item.isActive ? "Деактивировать" : "Активировать";
        setBtnText(text)
        setTextAnswModal(`Вы точно хотите ${text} данный аромат?`);
        setIdAnswModal(item.id)
        setOpAnswModal(true)
        user.setShadowOverlay(true);
    }
    const closeAnswModal = () => {
        setOpAnswModal(false);
        user.setShadowOverlay(false);
    }

    useEffect(() => {
        // console.log(9999999999999999)
        if(user.shadowOverlay == false) {
            setOpAnswModal(false)
            setOpenSuccessModal(false)
        }

    },[user.shadowOverlay])





    return (
        <div  className={"main_page_container_post"}>

            <div className={"post_container"}>
                <div className={"active_aroma wrong_items_wrap"}>
                    <div className={'heading_urp_block'}>
                        <span className={"name_page"}>Товары, артикул которых не был найден</span>



                    </div>
                    <div className={"heading_line_aroma"}>
                        <span className={"heading_number"}>№</span>
                        <span className={"heading_line_aroma_name"}>Наименование</span>
                        <span className={"heading_line_aroma_work wrong_items_container"}>Артикул</span>
                        <span className={"heading_line_aroma_work wrong_items_container"}>Внешний ключ</span>
                        <span className={"heading_line_aroma_work wrong_items_container"}>Дата</span>
                    </div>
                    {
                        wrongArray ? wrongArray.map((item, index) =>
                            <div className={"heading_line_aroma"} key={index}>
                                <span className={"heading_number"}>{index+1}</span>
                                <span className={'name_line_aroma_m'}>{item.name}</span>
                                <div className={"aroma_container_func wrong_items_container"}>
                                    <span> {item.articul} </span>
                                </div>
                                <div className={"aroma_container_func wrong_items_container"}>
                                    <span> {item.externalId} </span>
                                </div>
                                <div className={"aroma_container_func wrong_items_container"}>
                                    <span> {getHumanDate(item.createdAt)} </span>
                                </div>
                            </div>
                        ) : ''
                    }
                    <Pagination page={page} limit={limit} count={count}/>

                </div>

            </div>
        </div>
    );
});

export default WrongItemsPage;
