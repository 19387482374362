import React from 'react';

const SuccessModal = (props) => {
    return (
        <div className={props.isOpen ? "add_user_modal active" : "add_user_modal"}>
            <div className={"success_add_user_container"}>
                <img src="/yes.png" alt=""/>
                <span>{props.text}</span>
            </div>
        </div>
    );
};

export default SuccessModal;