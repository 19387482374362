import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import "./preloader.css";
import {Context} from "../../../index";

const Preloader = observer(() => {
    const {settings,user} = useContext(Context);

    return (
        <div  className={ settings.preloader ? "wrapper_shadow_preloader active" : "wrapper_shadow_preloader"}>
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

    );
});

export default Preloader;