import React, {useContext, useEffect, useState} from 'react';
import {registration, saveUserInfo} from "../../../http/userAPI";
import {Context} from "../../../index";

const UpdateUserModal = (props) => {
    const {settings,user} = useContext(Context);

    const [updLogin, setUpdLogin] = useState("");
    const [updName, setUpdName] = useState("");
    const [updPassword, setUpdPassword] = useState("");
    const [updConfirmPassword, setConfirmUpdPassword] = useState("");
    const [updCurrentRole, setUpdCurrentRole] = useState("")
    const [errorPassword,setErrorPassword] = useState("");
    const [headingError, setHeadingError] = useState('');
    const [loginError, setLoginError] = useState('');


    console.log(props.userData)
    console.log("userData")

    useEffect(() => {
        if(user.shadowOverlay === false) {
            props.setIsActiveUpdateModal(false);
        }
    },[user.shadowOverlay])

    useEffect(() => {
        setUpdLogin(props.userData.login)
        setUpdName(props.userData.name)
        setConfirmUpdPassword(props.userData.password)
        setUpdPassword(props.userData.password)
        setUpdCurrentRole(props.userData.role)

    },[props])


    const closeModal = () => {
        // user.setShadowOverlay(false);
        props.setIsActiveUpdateModal(false);
        props.setSuccessModalText('Данные изменены успешно')
        props.setOpenSuccessCreateModal(true)
    }

    const exitModal = () => {
        props.setIsActiveUpdateModal(false);
        user.setShadowOverlay(false);
        props.setOpenSuccessCreateModal(false)

    }
    const updateUser = async (updLogin,updPassword,updConfirmPassword,updName, updCurrentRole) => {
        let error = false;
        const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
        if(!updLogin) {
            setLoginError('Заполните E-mail')
            error = true;
        } else {
            setLoginError('');
        }


        if(!EMAIL_REGEXP.test(updLogin)) {
            setLoginError('Неправальный E-mail')
            error = true;
        } else {
            setLoginError('');
        }

        if(updPassword !== updConfirmPassword) {
            setErrorPassword('Пароли не совпадают')
            error = true;
        } else {
            setErrorPassword('')
        }

        if(updPassword.length < 6) {
            setErrorPassword('Минимум 6 букв/цифр')
            error = true;
            if(!updPassword) {
                setErrorPassword('Поле обязательно для заполнения')
                error = true;
            }
        }

        if(error) {
            return false;
        }

        setLoginError('');
        setErrorPassword('')
        setHeadingError('')

        const response = await saveUserInfo({login:updLogin, password:updPassword, name:updName, role:updCurrentRole}, props.userData);
        if(response.error) {
            setHeadingError(response.error)
            return false;
        }
        if(response.message) {
            if(response.message === "success") {
                closeModal();
                props.setRefresh(!props.refresh)
            }
        }



    }



    return (
        <div className={props.isOpen ? "add_user_modal active" : "add_user_modal"}>
            <div className={"add_user_field_container"}>
                <span className={"form_heading"}>ЗАПОЛНИТЕ ДАННЫЕ</span>
                <div className={'field-container_add_user'}>
                    <span className={'pls_add_user'}>Логин Email</span>
                    <div className={'input_container_add_user'}>
                        <input type='text'
                               value={updLogin ? updLogin : ""}
                               onChange={ e => setUpdLogin(e.target.value)}
                               className={'add_user_input'}
                        />
                    </div>
                    <span className={"error_message"}>{ loginError ? loginError : "" }</span>
                </div>
                <div className={'field-container_add_user'}>
                    <span className={'pls_add_user'}>Имя</span>
                    <div className={'input_container_add_user'}>
                        <input type='text'
                               value={updName ? updName : ""}
                               onChange={ e => setUpdName(e.target.value)}
                               className={'add_user_input'}
                        />
                    </div>


                </div>
                <div className={'field-container_add_user'}>
                    <span className={'pls_add_user'}>Пароль</span>
                    <div className={'input_container_add_user'}>
                        <input type='password'
                               value={updPassword ? updPassword : ""}
                               onChange={ e => setUpdPassword(e.target.value)}
                               className={'add_user_input'}
                        />
                    </div>
                    <span className={"error_message"}>{ errorPassword ? errorPassword : "" }</span>

                </div>
                <div className={'field-container_add_user'}>
                    <span className={'pls_add_user'}>Повторите пароль</span>
                    <div className={'input_container_add_user'}>
                        <input type='password'
                               value={updConfirmPassword ? updConfirmPassword : ""}
                               onChange={ e => setConfirmUpdPassword(e.target.value)}
                               className={'add_user_input'}
                        />
                    </div>
                </div>
                <span className={"error_message"}>{ headingError ? headingError : "" }</span>

                <div className={'checkbox-container'}>

                    {
                        props.roleMassive ? props.roleMassive.map((item, index) =>
                            <label key={index}>
                                <div
                                    className={updCurrentRole === item.role ? "checkbox_st active" : "checkbox_st" }
                                    onClick={e => setUpdCurrentRole(item.role)}
                                />
                                <span>{item.text}</span>
                            </label>


                        ) : ''
                    }
                </div>

                <div className={"wrks_buttons_container"}>
                    <span onClick={e => updateUser(updLogin,updPassword,updConfirmPassword,updName, updCurrentRole)} className={"mini-btn-style add"}>Сохранить</span>
                    <span onClick={e => exitModal()} className={"mini-btn-style"}>Отмена</span>
                </div>
            </div>
        </div>
    );
};

export default UpdateUserModal;

