import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import './sets.css';
import {Link, Route, Routes, useParams} from "react-router-dom";

import SuccessModal from "../../components/UI/SuccessModal/SuccessModal";

import PostavshikAddModal from "../../components/UI/PostavshikAddModal/PostavshikAddModal";
import {changeActive, createPost, fetchAllPost, fetchDeactivePosts, updatePost} from "../../http/PostavAPI";
import AnswerModalDeactive from "../../components/UI/AnswerModalDelete/AnswerModalDeactive";
import {fetchAllUsers} from "../../http/userAPI";
import PostavshikUpdateModal from "../../components/UI/PostavshikUpdateModal/PostavshikUpdateModal";
import {useLocation} from "react-router";
import Pagination from "../../components/UI/Paginstion";
import {changeActiveSet, deleteSet, fetchAllSet} from "../../http/setApi";








const Sets = observer(() => {



    const {settings,user} = useContext(Context);
    const [openAddPostModal, setOpenAddPostModal] =  useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [successText, setSuccessText] = useState('');
    const [errorAddPost, setErrorAddPost] = useState('');

    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState();
    const [selectedUser, setSelectedUser] = useState(null);
    const [openUserModal, setOpenUserModal] = useState(false)
    const [openSuccessCreateModal,setOpenSuccessCreateModal] = useState(false);
    const [allCount,setAllCount] = useState(0)
    const [postavArray, setPostavArray] = useState(null);
    const [refresh,setRefresh] = useState(false);
    /////

    const [errorUpdateModal, setErrorUpdateModal] = useState('');
    const [openUpdate, setOpenUpdate] = useState(false);
    const [idUpdate,setIdUpdate] = useState('');

    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')


    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }

    const limit = 50;
    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0)

    useEffect(() => {
        fetchAllSet(limit,(page-1)*limit,true,searchRoleParam,searchQuery).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }
            setAllCount(response.count)
            setPostavArray(response.rows);
            setCount(response.count)

            console.log(response.rows)
        })


    }, [page,searchRoleParam,searchQuery, refresh])

    ///DEactiv
    const [opAnswModal,setOpAnswModal] = useState(false);
    const [textAnswModal, setTextAnswModal] = useState('');
    const [btnText, setBtnText] = useState('');
    const [idAnswModal, setIdAnswModal] = useState('');


    const openAnswModalDeactive = (item) => {
        let text = item.isActive ? "Деактивировать" : "Активировать";
        setBtnText(text)
        setTextAnswModal(`Вы точно хотите ${text} этот набор?`);
        setIdAnswModal(item.id)
        setOpAnswModal(true)
        user.setShadowOverlay(true);
    }
    const deleteAndDeactiveHandler = async (fun,id) => {
        if(fun==="del") {

        }
        if(fun==='deactiv') {
            let response = await changeActiveSet(id);
            console.log(response)
            if(response.message) {
                let text = response.text;
                setSuccessText(`Набор ${text} успешно`)
                setOpAnswModal(false)
                setOpenSuccessModal(true)
                setRefresh(!refresh)
                if(!user.shadowOverlay){
                    user.setShadowOverlay(true)
                }
            }

            if(response.error) {
                return false;
            }
        }
    }

    const closeAnswModal = () => {
        setOpAnswModal(false);
        user.setShadowOverlay(false);
    }



    ///EXIT DELL
    const modalOpenPostHandler = () => {
        setOpenAddPostModal(true);
        user.setShadowOverlay(true);
    }

    const createPostHandler = async (name) => {
        let response = await createPost(name);
        console.log(response)
        if(response.message) {
            setSuccessText('Поставщик добавлен успешно')

            setOpenSuccessModal(true)
            setErrorAddPost("")
            setRefresh(!refresh)
            setOpenAddPostModal(false)

        }

        if(response.error) {
            setErrorAddPost(response.error)
        }
    }

    const openUpdHandler = (id) => {
        setIdUpdate(id);
        setOpenUpdate(true)
        user.setShadowOverlay(true);
    }
    const updatePostHandler = async (id,name) => {

        const response = await updatePost(id,name);

        if(response.message) {
            setSuccessText('Поставщик обновлен успешно')
            setOpenSuccessModal(true)
            setErrorUpdateModal("")
            setOpenUpdate(false);
            setRefresh(!refresh)

        }

        if(response.error) {
            setErrorUpdateModal(response.error)
        }
    }





    const [openDeleteModal, setOpenDeleteModal] = useState(false)
    const [deleteId,setDeleteId] = useState(null);

    const closeModalDelete = () => {
        setOpenDeleteModal(false)
        user.setShadowOverlay(false);

    }

    const openDeleteHandler = (id) => {
        setDeleteId(id)
        user.setShadowOverlay(true)
        setOpenDeleteModal(true);
    }


    const deleteHandler = () => {
        deleteSet(deleteId).then(response => {
           if(response.message) {
               // user.setShadowOverlay(false)
               setOpenDeleteModal(false)
               setSuccessText("Набор удален");
               setOpenSuccessModal(true);
               setRefresh(!refresh)
           }
        })
    }



    useEffect(() => {
        if(user.shadowOverlay === false) {
            setOpenAddPostModal(false);
            setOpenSuccessModal(false);
            setOpAnswModal(false)
            setOpenUpdate(false)
            setOpenDeleteModal(false)

        }
    },[user.shadowOverlay])


    if(user.getUserRights && user.getUserRights.view_sets) {
        return (
            <div  className={"main_page_container_post"}>
                <PostavshikAddModal error={errorAddPost}  createPostHandler={createPostHandler} setOpenAddPostModal={setOpenAddPostModal} isOpen={openAddPostModal} />
                <PostavshikUpdateModal error={errorUpdateModal} idUpd={idUpdate} updatePostHandler={updatePostHandler} setOpenUpdate={setOpenUpdate} isOpen={openUpdate} />

                <SuccessModal isOpen={openSuccessModal} text={successText} />
                <AnswerModalDeactive isOpen={opAnswModal} text={textAnswModal} buttonText={btnText} function={deleteAndDeactiveHandler} id={idAnswModal} close={closeAnswModal}  />
                <AnswerModalDeactive isOpen={openDeleteModal} text={"Вы точно хотите удалить данный набор?"} buttonText={"Удалить"} function={deleteHandler} id={deleteId} close={closeModalDelete}  />

                <div className={"post_container"}>
                    <div className={"active_posts_custom"}>
                        <div className={'heading_urp_block'}>
                            <span className={"name_page"}>НАБОРЫ</span>
                            <div className={'seach_container'}>
                                <input
                                    className={"search_input"}
                                    value={searchQuery ? searchQuery : ""}
                                    onChange={ e => setSearchQuery(e.target.value)}
                                    placeholder={"Поиск..."} type="text"/>
                                <div className={"abs_lupa"}>
                                    <img src="/lupa.png" alt=""/>
                                </div>
                            </div>


                            {
                                user.getUserRights.create_sets &&
                                <Link to={"/adm/sets/add/"}  className={"add_new_btn sets"}> Добавить набор </Link>
                            }





                        </div>
                        <div className={"heading_line_post"}>
                            <span className={"heading_line_post_name"}>Название набора</span>
                            <span className={"heading_line_post_work"}>Действие</span>
                        </div>
                        {
                            postavArray ? postavArray.map((item, index) =>
                                <div className={item.isActive ? "heading_line_post" : "heading_line_post deactive"} key={index}>
                                    <Link to={`/adm/sets/${item.id}`} className={'name_line_post'}>{item.name}</Link>

                                    <div className={ user.getUserRights && user.getUserRights.edit_sets  ? "post_container_func_custom" : "post_container_func_custom display_none"}>
                                        <Link to={`/adm/sets/edit/${item.id}`}  className={"bnt_post"}>Изменить</Link>
                                        <span onClick={e => openAnswModalDeactive(item)} className={item.isActive ? "bnt_post" : "bnt_post deact"}>{ item.isActive ? "Деактивировать" : "Активировать"}</span>
                                        <span className={"bnt_post"} onClick={e => openDeleteHandler(item.id)}>Удалить</span>
                                    </div>
                                </div>
                            ) : ''
                        }
                        <Pagination page={page} limit={limit} count={count}/>
                    </div>



                </div>

            </div>
        );
    } else {
        return (
            <div className={"close_page_for_you"}>
                <span>Доступ закрыт</span>
            </div>
        )
    }


});

export default Sets;


