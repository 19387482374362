import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import "./historyPage.css";


import {Link, Route, Routes, useParams} from "react-router-dom";
import {useLocation, useNavigate} from "react-router";
import {changeActiveBrand, fetchAllBrand, fetchDeactiveBrand} from "../../http/BrandApi";
import {changeActiveAroma, fetchAllAroma, fetchAllBuys, getHistoryAromaById} from "../../http/AromaApi";
import SuccessModal from "../../components/UI/SuccessModal/SuccessModal";
import AnswerModalDeactive from "../../components/UI/AnswerModalDelete/AnswerModalDeactive";
import Pagination from "../../components/UI/Paginstion";
import {getHumanDate} from "../../http/SupportFunctions";


const AromaHistoryPage = observer(() => {


    const Navigation = useNavigate()
    const {settings,user} = useContext(Context);
    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState();

    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')


    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }
    let id = params.id;



    const limit = 50;

    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0)
    const [history, setHistory] = useState(0)


    const [refresh,setRefresh] = useState(false);






        useEffect(() => {
            getHistoryAromaById(id,limit,(page-1)*limit,true,searchRoleParam,searchQuery).then(response => {
                if (response.error) {
                    //Ошибка, пока ничего не делаем
                    return false;
                }
                if(response.rows) {
                    console.log(response)
                    setHistory(response.rows)
                    setCount(response.count)
                }



            })


        }, [page,searchRoleParam,searchQuery, refresh])



    return (
        <div  className={"main_page_container_post"}>
            <div className={"add_new_btn"} onClick={() => Navigation(-1)}>Вернуться назад</div>
                    <div className={"title_page_hist"}>
                        История изменения данных
                    </div>

            <div className={"stroke_page_history first_row"} >
                <div className={"bord b_name strong"}>Что изменено</div>
                <div className={"bord b_value strong"}>Старое значение</div>
                <div className={"bord b_value strong"}>Новое значение</div>
                <div className={"bord user_id strong"}>ID пользователя</div>
                <div className={"bord user_login strong"}>Логин пользователя</div>
                <div className={"bord reason_change strong"}>Причина изменения</div>
                <div className={"bord create_date strong"}>Дата изменения</div>
            </div>
            {
                history ? history.map((item, index) =>
                    <div className={"stroke_page_history"} key={index}>
                        <div className={"bord b_name strong"}>{item.nameField ? item.nameField : ""}</div>
                        <div className={"bord b_value strong"}>{item.oldValue ? item.oldValue : ""}</div>
                        <div className={"bord b_value strong"}>{item.nevValue ? item.nevValue : ""}</div>
                        <div className={"bord user_id strong"}>{item.userId ? item.userId : ""}</div>
                        <div className={"bord user_login strong"}>{item.userName ? item.userName : ""}</div>
                        <div className={"bord reason_change strong"}>{item.reason ? item.reason : ""}</div>
                        <div className={"bord create_date strong"}>{item.createdAt ? getHumanDate(item.createdAt) : ""}</div>
                    </div>
                ) : ''
            }

                    <Pagination page={page} limit={limit} count={count}/>


        </div>
    );
});

export default AromaHistoryPage;

