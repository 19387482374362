import React, {useContext} from 'react'
import {Link} from 'react-router-dom'

import './style.css'

import {useLocation, useNavigate} from "react-router";
import {Context} from "../../../index";
import {all} from "axios";

const Pagination = ({count, limit, page, pageName, onChangePage = () => {}}) => {


    let namePag = "page";
    if(pageName) {
        namePag = pageName;
    }


    const {settings} = useContext(Context)

    const location = useLocation()
    const Navigate = useNavigate();
    if (!count || !limit)
        return ''

    if (!page || page <= 0)
        page = 1

    if (count <= limit)
        return ''

    const allPages = Math.ceil(count / limit)

    const pageItems = []

    if (page < 1) {
        pageItems.push(page)
        pageItems.push(false)
        pageItems.push(allPages)
    } else if (page === allPages) {
        pageItems.push(page)
        pageItems.push(false)
        pageItems.push(allPages)
    } else {
        pageItems.push(page)
        pageItems.push(false)
        pageItems.push(allPages)
    }
    console.log(allPages+"all")
    console.log(pageItems);
    console.log('pageItems')

    let pagesArray = []
    let pagAfter = 3;


    for (let i = page; i > page-3 && i !== 0; i--) { // выведет 0, затем 1, затем 2
        pagesArray.push(i);
    }
    pagesArray.reverse()

    let pagbefore = (allPages - page) < 3?  allPages - page : 3;

    for (let i = page; i < page+pagbefore; i++) { // выведет 0, затем 1, затем 2
        if(i !== page) {
            pagesArray.push(i);
        }

    }

    if(page > allPages) {

        Navigate(location.pathname+`?${namePag}=${allPages}`);

    }



    // if(page < 5) {
    //     for (let onePage = 1; onePage <= 5 && onePage <= allPages; onePage++)
    //         pageItems.push(onePage)
    //
    //     if(allPages > 5) {
    //         if(allPages - 5 > 1)
    //             pageItems.push(false)
    //
    //         pageItems.push(allPages)
    //     }
    // } else if(page >= allPages - 3) {
    //     pageItems.push(1)
    //     pageItems.push(false)
    //     for (let onePage = allPages - 4; onePage <= allPages; onePage++)
    //         pageItems.push(onePage)
    // } else {
    //     pageItems.push(1)
    //     pageItems.push(false)
    //     pageItems.push(page-1)
    //     pageItems.push(page)
    //     pageItems.push(page+1)
    //     pageItems.push(false)
    //     pageItems.push(allPages)
    // }


    return (
        <div className={"pgn_wrapp"}>
        <div className="pagination">
            {
                page > 1
                &&
                <Link to={page - 1 > 1 ? `?${namePag}=${page - 1}` : location.pathname}
                      onClick={() => onChangePage(page-1)}
                      className="pagination__btn pagination-prev">
                    <span>{settings.projectWidth && settings.projectWidth > 768 ? '❮' : '❮'}</span>
                </Link>
            }


            {pagesArray.map((onePage, index) => (
                <Link
                    key={index}
                    to={onePage > 1 ? `?${namePag}=${onePage}` : location.pathname}
                    onClick={() => onChangePage(onePage)}
                    //добавила || onePage и 'non_click_pagination', блокирует клик на "из"
                    className={`pagination__btn ${page === onePage ? 'current' : ""}`}>
                    <span className={''}>{onePage || ''}</span>
                </Link>
            ))}
            {
                page < allPages
                &&
                <Link to={`?${namePag}=${allPages}`}
                      onClick={() => onChangePage(page+1)}
                      className="pagination__btn pagination-next">
                    <span>...  <span className={"ml-left"}>{allPages}</span></span>
                </Link>
            }


            {
                page < allPages
                &&
                <Link to={`?${namePag}=${page + 1}`}
                      onClick={() => onChangePage(page+1)}
                      className="pagination__btn pagination-next">
                    <span>❯</span>
                </Link>
            }
        </div>
            <div className={"shadow_mnn"}>{page*limit-limit > 0 ? page*limit-limit : 1   } - {page*limit}  из {count}</div>
        </div>
    )
}

export default Pagination
