import React, {useContext, useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import {Link, Route, Routes, useParams} from "react-router-dom";
import "./User.css";
import {changeActiveUser, deleteUser, fetchAllUsers, registration} from "../../http/userAPI";
import UpdateUserModal from "../../components/UI/UpdateUserModel/UpdateUserModal";
import SuccessModal from "../../components/UI/SuccessModal/SuccessModal";
import AnswerModalDeactive from "../../components/UI/AnswerModalDelete/AnswerModalDeactive";
import AnswerModalActiveUser from "../../components/UI/AnswerModalActiveUser/AnswerModalActiveUser";
import Pagination from "../../components/UI/Paginstion";
import {useLocation} from "react-router";
import {getHumanDate} from "../../http/SupportFunctions";








const UserPage = observer(() => {



    const {settings,user} = useContext(Context);

    const location = useLocation();
    const params = useParams()
    const queryParams = new URLSearchParams(window.location.search)
    let page = +queryParams.get('page')


    if (location.search) {
        const params = new URLSearchParams(location.search);
        page = parseInt(params.get("page")); // is the number

    } else if (!page || page <= 0) {
        page = 1
    }

    const limit = 50;
    const [startOffset, setStartOffset] = useState(0);
    const [offset, setOffset] = useState(0);
    const [count, setCount] = useState(0)



    const [searchRoleParam, setSearchRoleParam] = useState("");
    const [searchQuery,setSearchQuery] = useState();
    const [selectedUser, setSelectedUser] = useState(null);
    const [openUserModal, setOpenUserModal] = useState(false)
    const [openSuccessCreateModal,setOpenSuccessCreateModal] = useState(false);
    const [allCount,setAllCount] = useState(0)
    const [usersArray, setUsersArray] = useState(null);

    const [login, setLogin] = useState('');
    const [loginError, setLoginError] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorPassword,setErrorPassword] = useState("");
    const [headingError, setHeadingError] = useState('');
    const [roleMassive, setRoleMassive] = useState([
        {role:"user", text:"Сотрудник"},
        {role:"admin", text:"Администратор"}
    ])


    //## Update user modal data //

    const [isActiveUpdateModal, setIsActiveUpdateModal] = useState(false);
    const [userDataForUpdate, setUserDataForUpdate] = useState('');
    const [successModalText,setSuccessModalText] = useState('');
    let openUserUpdateModalHandler = (item) => {
        setUserDataForUpdate(item);
        setIsActiveUpdateModal(true);
        user.setShadowOverlay(true);
    }

    let closeAnswerModal = () => {
        setOpenAnswerModal(false)
        user.setShadowOverlay(false)
    }


    //##----------END user update functional //




    //## DELETE USER FUNCTIONAL //

    const [openAnswerModal, setOpenAnswerModal] = useState(false);
    const [userIdForDelete, setUserIdForDelete] = useState('');
    const [answerText,setAnswerText] = useState('')
    const openAnswerModalHandler = (dataUser) => {
        setUserIdForDelete(dataUser.id);
        setAnswerText('Вы точно хотите удалить данного пользователя?')
        setOpenAnswerModal(true);
        user.setShadowOverlay(true);
    }
    const deleteUserHandler = async (modelUse,id) => {

       const response = await deleteUser(id);
        if(response.message) {
            setOpenAnswerModal(false);
            setOpenSuccessCreateModal(true)
            setSuccessModalText('Пользователь успешно удален')
            setRefresh(!refresh)
        }
    }

    //##----------END DELETE USER FUNCTIONAL

    //## ACTIVE DEACTIVE USER FUNCTIONAL //


    const [openModalActiveAndDeactiveUser, setOpenModalActiveAndDeactiveUser] = useState(false);
    const [activeModalAnswerText, setActiveModalAnswerText] = useState('')
    const [idUserForChangeActive, setIdUserForChangeActive] = useState('');
    let changeActiveHandlerOpenModal =  (dataUser) => {
        let text = dataUser.isActive ? "Деактивировать" : "активировать";
        setActiveModalAnswerText(`Вы точно хотите ${text} данного пользователя?`);
        setIdUserForChangeActive(dataUser.id);
        setOpenModalActiveAndDeactiveUser(true);
        user.setShadowOverlay(true);
    }

    let changeActiveUserHandler = async (id) => {
            const response = await changeActiveUser(idUserForChangeActive);
        if(response.message) {
            setOpenModalActiveAndDeactiveUser(false);
            setOpenSuccessCreateModal(true)
            setSuccessModalText(`Пользователь успешно ${response.text}`);
            setRefresh(!refresh)
        }
    }
    let closeChangeActiveModal = () => {
        setIsActiveUpdateModal(false);
        user.setShadowOverlay(false);
    }
    //##----------ACTIVE DEACTIVE USER FUNCTIONAL


    const findObjectByParameter = (parameter) => {
        const foundObject = roleMassive.find(obj => obj.role === parameter);

        if (foundObject) {
            console.log('Found object:', foundObject);
            return foundObject.text;
        } else {
            console.log('Object not found');
        }
    }




    const [selectedRole,setSelectedRole] = useState(roleMassive[0]);
    const [refresh,setRefresh] = useState(true)

    useEffect(() => {
        fetchAllUsers(limit,(page-1)*limit,true,searchRoleParam,searchQuery).then(response => {
            if (response.error) {
                //Ошибка, пока ничего не делаем
                return false;
            }
            if (!response.error) {
                setOffset(offset + limit)
                if (response.length < limit) {

                }
                // let reduceProductsArray = [...productsArray];
                // let finishProductsArray = reduceProductsArray.concat(response);
                setAllCount(response.count)
                setUsersArray(response.rows);
                setCount(response.count)
                // setProductsArray(response)
            }


            console.log(response.rows)
        })
    }, [page,searchRoleParam,searchQuery, refresh])


    const openModalHandler = () => {
            user.setShadowOverlay(true)
            setOpenUserModal(true)
    }
    const closeModalHandler = () => {
        user.setShadowOverlay(false)
        setOpenUserModal(false)
        setName("")
        setPassword('')
        setConfirmPassword('')
        setLogin('')
        setLoginError('')
        setHeadingError('')
    }

    useEffect(() => {
        if(user.shadowOverlay === false) {
            setOpenUserModal(false)
            setOpenSuccessCreateModal(false)
            setOpenAnswerModal(false)
            setOpenModalActiveAndDeactiveUser(false)
        }
    },[user.shadowOverlay])



const createNewUser = async (login,password,confirmPassword,name, role) => {
        let error = false;
    const EMAIL_REGEXP = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
        if(!login) {
            setLoginError('Заполните E-mail')
            error = true;
        } else {
            setLoginError('');
        }


        if(!EMAIL_REGEXP.test(login)) {
            setLoginError('Неправальный E-mail')
            error = true;
        } else {
            setLoginError('');
        }

    if(password !== confirmPassword) {
            setErrorPassword('Пароли не совпадают')
        error = true;
        } else {
        setErrorPassword('')
    }

        if(password.length < 6) {
            setErrorPassword('Минимум 6 букв/цифр')
            error = true;
            if(!password) {
                setErrorPassword('Поле обязательно для заполнения')
                error = true;
            }
        }

        if(error) {
            return false;
        }

    setLoginError('');
    setErrorPassword('')
    setHeadingError('')

        const response = await registration(login,password,name, role);
        if(!response.data) {
            setHeadingError('Что-то пошло не так.')
            return false;
        }
        if(response.data.error) {
            setLoginError(response.data.error)
            return false;
        }


        setOpenUserModal(false)
        setName("")
        setPassword('')
        setConfirmPassword('')
        setLogin('')
        setLoginError('');
        setErrorPassword('')
        setHeadingError('')
        setOpenSuccessCreateModal(true)
        setSuccessModalText('Пользователь добавлен успешно')
        setRefresh(!refresh)

}
    const [passwordFieldType, setPasswordFieldType] = useState('password');
    const changeTypeField = (passwordFieldType) => {
        if(passwordFieldType === "password") {
            setPasswordFieldType('text');
            return false;
        }
        setPasswordFieldType('password');

    }

    return (
        <div  className={"main_user_page"}>
            <div className={openUserModal ? "add_user_modal active" : "add_user_modal"}>
                    <div className={"add_user_field_container"}>
                        <span className={"form_heading"}>ЗАПОЛНИТЕ ДАННЫЕ</span>
                        <div className={'field-container_add_user'}>
                            <span className={'pls_add_user'}>Email</span>
                            <div className={'input_container_add_user'}>
                                <input type='text'
                                       value={login ? login : ""}
                                       onChange={ e => setLogin(e.target.value)}
                                       className={'add_user_input'}
                                />
                            </div>
                            <span className={"error_message"}>{ loginError ? loginError : "" }</span>
                        </div>
                        <div className={'field-container_add_user'}>
                            <span className={'pls_add_user'}>Имя</span>
                            <div className={'input_container_add_user'}>
                                <input type='text'
                                       value={name ? name : ""}
                                       onChange={ e => setName(e.target.value)}
                                       className={'add_user_input'}
                                />
                            </div>


                        </div>
                        <div className={'field-container_add_user'}>
                            <span className={'pls_add_user'}>Пароль</span>
                            <div className={'input_container_add_user'}>
                                <input type={passwordFieldType}
                                       value={password ? password : ""}
                                       onChange={ e => setPassword(e.target.value)}
                                       className={'add_user_input'}
                                />
                                <div className={'glase outp'}
                                     onClick={ e => changeTypeField(passwordFieldType)}
                                >
                                    <img src="../show.png" alt=""/>
                                </div>
                            </div>
                            <span className={"error_message"}>{ errorPassword ? errorPassword : "" }</span>

                        </div>
                        <div className={'field-container_add_user'}>
                            <span className={'pls_add_user'}>Повторите пароль</span>
                            <div className={'input_container_add_user'}>
                                <input type={passwordFieldType}
                                       value={confirmPassword ? confirmPassword : ""}
                                       onChange={ e => setConfirmPassword(e.target.value)}
                                       className={'add_user_input'}
                                />
                            </div>
                        </div>
                        <span className={"error_message"}>{ headingError ? headingError : "" }</span>

                        <div className={'checkbox-container'}>

                            {
                                roleMassive ? roleMassive.map((item, index) =>
                                    <label key={index}>
                                        <div
                                            className={selectedRole.role === item.role ? "checkbox_st active" : "checkbox_st" }
                                            onClick={e => setSelectedRole(item)}
                                        />
                                        <span>{item.text}</span>
                                    </label>


                                ) : ''
                            }
                        </div>

                        <div className={"wrks_buttons_container"}>
                            <span onClick={e => createNewUser(login,password,confirmPassword,name, selectedRole.role)} className={"mini-btn-style add"}>Добавить</span>
                            <span onClick={e => closeModalHandler()} className={"mini-btn-style"}>Отмена</span>
                        </div>
                    </div>
            </div>
            <UpdateUserModal
                isOpen={isActiveUpdateModal}
                userData={userDataForUpdate}
                roleMassive={roleMassive}
                setIsActiveUpdateModal={setIsActiveUpdateModal}
                setSuccessModalText={setSuccessModalText}
                setOpenSuccessCreateModal={setOpenSuccessCreateModal}
                setRefresh={setRefresh}
                refresh={refresh}
            />
            <AnswerModalActiveUser isOpen={openModalActiveAndDeactiveUser} text={activeModalAnswerText} buttonText={"Изменить"} function={changeActiveUserHandler} id={idUserForChangeActive} close={closeChangeActiveModal}  />
            <AnswerModalDeactive isOpen={openAnswerModal} text={answerText} buttonText={'Удалить'} function={deleteUserHandler} id={userIdForDelete} close={closeAnswerModal}  />

            <SuccessModal isOpen={openSuccessCreateModal} text={successModalText} />
            <div className="controll_panel">
                <span className={'heading_page_user'}>ПОЛЬЗОВАТЕЛИ</span>
                <span
                    onClick={ e => openModalHandler()}
                    className={'heading_btn_add_user'}>Добавить пользователя</span>
            </div>
            <div className={'user_box'}>
                <div className={"line_container"}>
                    <div className={'line_item'}>Логин</div>
                    <div className={'line_item'}>Имя</div>
                    <div className={'line_item'}>Роль</div>
                    <div className={'line_item'}>Дата регистрации</div>
                    <div className={'line_item'}>Действие</div>
                </div>
                {
                    usersArray ? usersArray.map((item, index) =>
                        <div key={index} className={item.isActive ? "line_container" : "line_container deactive" }>
                            <div className={'line_item'}>{item.login}</div>
                            <div className={'line_item'}>{item.name}</div>
                            <div className={'line_item'}>{findObjectByParameter(item.role)}</div>
                            <div className={'line_item'}>{getHumanDate(item.createdAt)}</div>
                            <div className={'line_item'}>

                                {
                                    user && user.user.role == "admin" &&
                                    <div className={'functional_select'}>
                                        <span className={'heading_span_menu'}>Выберите действие</span>
                                        <div className={'select_menu'}>
                                            <span onClick={e => openUserUpdateModalHandler(item)} className={'select_menu_item'}>Изменить</span>
                                            <span onClick={e => openAnswerModalHandler(item)} className={'select_menu_item'}>Удалить</span>
                                            <Link to={`/adm/user/rights/${item.id}`} className={'select_menu_item'}>Права пользователя</Link>
                                            <span onClick={e => changeActiveHandlerOpenModal(item)} className={'select_menu_item'}>{item.isActive ? "Деактивировать" : "Активировать"}</span>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>


                    ) : ''
                }
                <Pagination page={page} limit={limit} count={count}/>
            </div>


        </div>
    );
});

export default UserPage;


