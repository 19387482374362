import React, {useContext, useEffect, useState} from 'react';
import './Footer.css'
import {observer} from "mobx-react-lite";
import {Context} from "../../index";

const Footer = observer(() => {

    // const {settings} = useContext(Context);





    return (
        <footer id={"footer"} className={"footer"}>

        </footer>
    );
});

export default Footer;



