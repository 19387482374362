import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {
    authRoutes, publicRoutes,

} from '../routes';

import {Route, Routes, Navigate} from "react-router-dom";
import {Context} from "../index";
import AuthPage from "../pages/authPage/AuthPage";
import UserPage from "../pages/administrator/UserPage";


const AppRouter = observer((pros) => {

    const {user} = useContext(Context);

    console.log(user.isAuth)
    return (
        <Routes>

            {
                user.isAuth  &&  authRoutes.map(({path, Component}) =>
                    <Route key={path} path={path} element={ <Component/> } />)
            }
            {  publicRoutes.map(({path, Component}) =>
                <Route key={path} path={path} element={ <Component/> } />
            )}
            <Route exact path={"/adm/*"} element={ <AuthPage />} />
            {/**/}

            <Route path="*" element={<Navigate to="/404/" />} />

        </Routes>
    );
});

export default AppRouter;

